define("smile-developers/templates/auth/login", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "x6jBro0E",
    "block": "[[[6,[39,0],null,[[\"smileGlyphSource\"],[\"/assets/images/smile/glyph-grey.svg\"]],[[\"default\"],[[[[6,[39,1],null,[[\"logoSrc\",\"subheadingText\"],[\"/assets/icons/smile/logo-white-bg.svg\",\"Access your Developer Dashboard\"]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,2],null,[[\"email\",\"hasRecoveredPassword\",\"hasResetPassword\",\"isResetPasswordTokenExpired\",\"onAuthenticate\",\"onForgotPassword\"],[[28,[37,3],[[33,4]],null],[28,[37,3],[[33,5]],null],[28,[37,3],[[33,6]],null],[28,[37,3],[[33,7]],null],[28,[37,8],[[33,9]],null],[28,[37,10],[\"auth.recover\"],null]]]]],[1,\"\\n\"]],[]]]]]],[]]]]]],[],false,[\"auth-page\",\"auth-card\",\"auth-login-form\",\"readonly\",\"email\",\"hasRecoveredPassword\",\"hasResetPassword\",\"isResetPasswordTokenExpired\",\"perform\",\"authenticate\",\"transition-to\"]]",
    "moduleName": "smile-developers/templates/auth/login.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});