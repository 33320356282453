define("smile-developers/helpers/replace-with", ["exports", "ember-route-helpers/helpers/replace-with"], function (_exports, _replaceWith) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _replaceWith.default;
    }
  });
  Object.defineProperty(_exports, "replaceWith", {
    enumerable: true,
    get: function get() {
      return _replaceWith.replaceWith;
    }
  });
});