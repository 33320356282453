define("smile-developers/helpers/readable-array", ["exports", "@ember/component/helper", "@ember/object/internals", "@ember/utils"], function (_exports, _helper, _internals, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.readableArray = readableArray;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Joins the passed-in list of strings to make a readable output.
   *
   * examples:
   *
   * numbers = [ 'one', 'two', 'three' ]
   * {{readable-array numbers}}
   *  => 'one, two or three'
   *
   * fruits = [ 'apples', 'oranges', 'bananas' ]
   * {{readable-array numbers lastWordSeparator="and"}}
   *  => 'apples, oranges and bananas'
   */
  function readableArray(_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 1),
        values = _ref3[0];

    var _ref2$separator = _ref2.separator,
        separator = _ref2$separator === void 0 ? ', ' : _ref2$separator,
        _ref2$lastWordSeparat = _ref2.lastWordSeparator,
        lastWordSeparator = _ref2$lastWordSeparat === void 0 ? 'or' : _ref2$lastWordSeparat;

    if ((0, _utils.isEmpty)(values)) {
      return '';
    }

    if (values.length > 1) {
      values = (0, _internals.copy)(values);
      var lastValue = values.pop();
      return "".concat(values.join(separator), " ").concat(lastWordSeparator, " ").concat(lastValue);
    }

    return values[0];
  }

  var _default = (0, _helper.helper)(readableArray);

  _exports.default = _default;
});