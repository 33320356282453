define("smile-developers/templates/components/app/overview-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "uR210xAa",
    "block": "[[[6,[39,0],null,[[\"sectioned\"],[true]],[[\"default\"],[[[[6,[39,1],null,[[\"onSubmit\"],[[28,[37,2],[[30,0],[33,3]],null]]],[[\"default\"],[[[[6,[39,4],null,null,[[\"default\"],[[[[1,\"      \"],[1,[28,[35,5],null,[[\"label\",\"placeholder\",\"helpText\",\"value\",\"error\",\"onChange\"],[\"App overview\",\"e.g. Email marketing service for managing contacts\",\"Short description of the app\",[33,6,[\"shortDescription\"]],[33,6,[\"error\",\"shortDescription\",\"validation\",\"firstObject\"]],[28,[37,2],[[30,0],[28,[37,7],[[33,6,[\"shortDescription\"]]],null]],null]]]]],[1,\"\\n\\n\"],[6,[39,8],null,[[\"label\"],[\"App features (max. 5)\"]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,9],null,[[\"benefits\",\"onBenefitsChanged\"],[[33,10,[\"benefitsList\"]],[28,[37,2],[[30,0],[28,[37,7],[[33,6,[\"benefitsList\"]]],null]],null]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n      \"],[1,[28,[35,5],null,[[\"label\",\"placeholder\",\"hint\",\"value\",\"error\",\"onChange\"],[\"Documentation URL\",\"e.g. docs.yourapp.com\",\"Direct merchants to a guide for your Smile app.\",[33,6,[\"documentationUrl\"]],[33,6,[\"error\",\"documentationUrl\",\"validation\",\"firstObject\"]],[28,[37,2],[[30,0],[28,[37,7],[[33,6,[\"documentationUrl\"]]],null]],null]]]]],[1,\"\\n\"]],[]]]]]],[]]]]]],[]]]]]],[],false,[\"polaris-card\",\"polaris-form\",\"action\",\"onSave\",\"polaris-form-layout\",\"polaris-text-field\",\"appListingChangeset\",\"mut\",\"polaris-labelled\",\"app/editable-benefits-list\",\"appListing\"]]",
    "moduleName": "smile-developers/templates/components/app/overview-card.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});