define("smile-developers/validations/partner-user", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    firstName: (0, _validators.validatePresence)(true),
    lastName: (0, _validators.validatePresence)(true),
    email: (0, _validators.validateFormat)({
      type: 'email'
    }),
    password: [(0, _validators.validateLength)({
      allowNone: true,
      allowBlank: true,
      min: 8
    })],
    passwordConfirmation: (0, _validators.validateConfirmation)({
      on: 'password',
      allowNone: true,
      allowBlank: true
    })
  };
  _exports.default = _default;
});