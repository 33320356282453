define("smile-developers/authenticators/partner-user", ["exports", "ember-simple-auth/authenticators/devise", "@ember/service", "@ember/object", "@ember/runloop"], function (_exports, _devise, _service, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSON_CONTENT_TYPE = 'application/json';

  var _default = _devise.default.extend({
    config: (0, _service.inject)(),

    /**
     * The identification attribute name. This will be used in the request and
     * also be expected in the server's response.
     *
     * @property identificationAttributeName
     * @type {String}
     */
    identificationAttributeName: 'email',

    /**
     * The token attribute name. This will be used in the request and also be
     * expected in the server's response.
     *
     * @property tokenAttributeName
     * @type {String}
     */
    tokenAttributeName: 'authentication_token',

    /**
     * The devise resource name. This will be used in the request and also be
     * expected in the server's response.
     *
     * @property resourceName
     * @type {String}
     */
    resourceName: 'partner_user',

    /**
     * When authentication fails, the rejection callback is provided with the whole
     * Fetch API [Response](https://fetch.spec.whatwg.org/#response-class) object
     * instead of its responseJSON or responseText.
     *
     * This is useful for cases when the backend provides additional context not
     * available in the response body.
     *
     * @property rejectWithResponse
     * @type {Boolean}
     */
    rejectWithResponse: true,
    serverTokenEndpoint: (0, _object.computed)('config.smile.apiUrl', {
      get: function get() {
        return "".concat(this.get('config.smile.apiUrl'), "/partner_users/sign_in");
      }
    }),
    authenticate: function authenticate(identification, password) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        var useResponse = _this.rejectWithResponse;
        var resourceName = _this.resourceName,
            identificationAttributeName = _this.identificationAttributeName,
            tokenAttributeName = _this.tokenAttributeName;
        var data = {}; // Allow user to login with either email/password or authentication_token

        if (identification === null) {
          data[resourceName] = {
            authentication_token: password
          };
        } else {
          data[resourceName] = {
            password: password
          };
          data[resourceName][identificationAttributeName] = identification;
        } // Add our custom header to denote it's coming from our apps


        var options = {
          headers: {
            'Smile-Client': _this.config.get('modulePrefix'),
            // We also include what parent does, because it's not doing a deep merge :(
            accept: JSON_CONTENT_TYPE,
            'content-type': JSON_CONTENT_TYPE
          }
        };

        _this.makeRequest(data, options).then(function (response) {
          if (response.ok) {
            response.json().then(function (json) {
              if (_this._validate(json)) {
                var _resourceName = _this.resourceName;

                var _json = json[_resourceName] ? json[_resourceName] : json;

                (0, _runloop.run)(null, resolve, _json);
              } else {
                (0, _runloop.run)(null, reject, "Check that server response includes ".concat(tokenAttributeName, " and ").concat(identificationAttributeName));
              }
            });
          } else {
            if (useResponse) {
              (0, _runloop.run)(null, reject, response);
            } else {
              response.json().then(function (json) {
                return (0, _runloop.run)(null, reject, json);
              });
            }
          }
        }).catch(function (error) {
          return (0, _runloop.run)(null, reject, error);
        });
      });
    }
  });

  _exports.default = _default;
});