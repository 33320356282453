define("smile-developers/routes/auth/reset", ["exports", "smile-developers/routes/unauthenticated", "@ember/utils"], function (_exports, _unauthenticated, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _unauthenticated.default.extend({
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments); // If we don't have the reset password token, nothing to do here


      if ((0, _utils.isBlank)(transition.to.queryParams.reset_password_token)) {
        transition.send('goLogin');
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('resetPasswordToken', null);
      }
    }
  });

  _exports.default = _default;
});