define("smile-developers/templates/components/collapsible-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wMKK2F5k",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[42,[28,[37,2],[[28,[37,2],[[33,3]],null]],null],null,[[[6,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[1,\"      \"],[1,[28,[35,4],null,[[\"external\",\"url\",\"text\"],[true,[30,2,[\"url\"]],[30,2,[\"text\"]]]]]],[1,\"\\n\"]],[]]]]]],[2]],null]],[1]]]]],[1,\"\\n\"],[41,[33,6],[[[6,[39,7],null,[[\"class\",\"spacing\",\"distribution\"],[\"collapse-toggle\",\"tight\",\"center\"]],[[\"default\"],[[[[6,[30,3,[\"item\"]],null,null,[[\"default\"],[[[[6,[39,4],null,[[\"onClick\"],[[28,[37,8],[[30,0],[28,[37,9],[[33,10]],null],[28,[37,11],[[33,10]],null]],null]]],[[\"default\"],[[[[6,[39,7],null,[[\"spacing\",\"alignment\"],[\"extraTight\",\"center\"]],[[\"default\"],[[[[1,\"          \"],[1,[28,[30,4,[\"item\"]],null,[[\"text\"],[[33,12]]]]],[1,\"\\n\\n          \"],[1,[28,[35,13],null,[[\"color\",\"source\"],[\"blue\",[52,[33,10],\"caret-up\",\"caret-down\"]]]]],[1,\"\\n\"]],[4]]]]]],[]]]]]],[]]]]]],[3]]]]]],[]],null]],[\"list\",\"item\",\"stack\",\"stack\"],false,[\"polaris-list\",\"each\",\"-track-array\",\"items\",\"polaris-link\",\"if\",\"shouldRenderTrigger\",\"polaris-stack\",\"action\",\"mut\",\"shouldDisplayAllItems\",\"not\",\"displayTriggerText\",\"polaris-icon\"]]",
    "moduleName": "smile-developers/templates/components/collapsible-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});