define("smile-developers/services/version-manager", ["exports", "ember-smile-core/services/version-manager"], function (_exports, _versionManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _versionManager.default;
    }
  });
});