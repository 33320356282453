define("smile-developers/templates/components/polaris-modals/delete-events-before-webhook", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "YWyetjCQ",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[6,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[6,[39,1],null,[[\"alignment\"],[\"center\"]],[[\"default\"],[[[[6,[30,2,[\"item\"]],null,[[\"fill\"],[true]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,2],null,[[\"size\",\"text\"],[\"small\",\"Cannot remove request URL\"]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"item\"]],null,null,[[\"default\"],[[[[6,[39,3],null,[[\"class\",\"onClick\"],[\"polaris-modal-close-btn\",[28,[37,4],[[30,0],\"closeModal\"],null]]],[[\"default\"],[[[[1,\"          \"],[1,[28,[35,5],null,[[\"source\"],[\"cancel\"]]]],[1,\"\\n\"]],[]]]]]],[]]]]]],[2]]]]]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[1,\"    \"],[10,1],[12],[1,\"\\n      To remove this URL you will need to delete all your webhook events.\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[6,[39,1],null,[[\"distribution\"],[\"trailing\"]],[[\"default\"],[[[[6,[39,6],null,null,[[\"default\"],[[[[1,\"        \"],[1,[28,[35,7],null,[[\"text\",\"onClick\"],[\"OK\",[28,[37,4],[[30,0],\"closeModal\"],null]]]]],[1,\"\\n\"]],[]]]]]],[]]]]]],[]]]]]],[1]]]]]],[\"card\",\"stack\"],false,[\"polaris-card\",\"polaris-stack\",\"polaris-display-text\",\"polaris-link\",\"action\",\"polaris-icon\",\"polaris-button-group\",\"polaris-button\"]]",
    "moduleName": "smile-developers/templates/components/polaris-modals/delete-events-before-webhook.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});