define("smile-developers/components/app/info-card", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',

    /**
     * The app being viewed/edited.
     * @type {Object}
     */
    app: null,
    appChangeset: null,
    appListingChangeset: null,
    appListing: (0, _computed.reads)('app.appListing')
  });

  _exports.default = _default;
});