define("smile-developers/templates/components/app-nav-content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "6YrDx2z5",
    "block": "[[[6,[33,0,[\"group\"]],null,null,[[\"default\"],[[[[1,\"      \"],[1,[28,[30,1,[\"item\"]],null,[[\"label\",\"icon\",\"linkParams\"],[\"Managed accounts\",\"smile/customers\",[28,[37,1],null,[[\"route\"],[\"managed-accounts\"]]]]]]],[1,\"\\n\\n      \"],[1,[28,[30,1,[\"item\"]],null,[[\"label\",\"icon\",\"linkParams\"],[\"Apps\",\"smile/apps\",[28,[37,1],null,[[\"route\"],[\"apps\"]]]]]]],[1,\"\\n\\n      \"],[1,[28,[30,1,[\"item\"]],null,[[\"label\",\"icon\",\"linkParams\"],[\"Resources\",\"smile/resources\",[28,[37,1],null,[[\"route\"],[\"resources\"]]]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[6,[33,0,[\"group\"]],null,null,[[\"default\"],[[[[1,\"      \"],[1,[28,[30,2,[\"item\"]],null,[[\"label\",\"icon\",\"linkParams\"],[\"Settings\",\"smile/settings\",[28,[37,1],null,[[\"route\"],[\"settings\"]]]]]]],[1,\"\\n\"]],[2]]]]]],[\"group\",\"group\"],false,[\"nav\",\"hash\"]]",
    "moduleName": "smile-developers/templates/components/app-nav-content.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});