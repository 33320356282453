define("smile-developers/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.hasClass('liquid-animation-fade-in-out'), this.toValue(true), this.use('fade'), this.includingInitialRender());
  }
});