define("smile-developers/components/editable-list/existing-items", ["exports", "ember-smile-core/components/editable-list/existing-items"], function (_exports, _existingItems) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _existingItems.default;
    }
  });
});