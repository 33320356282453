define("smile-developers/templates/components/route-page/apps/app/information-tab", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "YAzBo1hN",
    "block": "[[[6,[39,0],null,[[\"title\",\"description\"],[\"App information\",\"These details can help you keep track of your private apps.\"]],[[\"default\"],[[[[6,[39,1],null,[[\"sectioned\"],[true]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,2],null,[[\"title\",\"minWidth\",\"minHeight\",\"iconRecommendation\",\"iconUrl\",\"onUploadedFileKey\",\"onUploadedFileUrl\"],[\"App icon\",200,200,\"Must be a .jpg or .png, and minimum of 200x200px\",[33,3,[\"customizedIconUrl\"]],[28,[37,4],[[30,0],[28,[37,5],[[33,3,[\"customizedIconTempS3Key\"]]],null]],null],[28,[37,4],[[30,0],[28,[37,5],[[33,3,[\"customizedIconUrl\"]]],null]],null]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n  \"],[1,[28,[35,6],null,[[\"appChangeset\",\"appListingChangeset\",\"onSave\"],[[33,7],[33,3],[28,[37,4],[[30,0],[33,8]],null]]]]],[1,\"\\n\\n  \"],[1,[28,[35,9],null,[[\"appListing\",\"appListingChangeset\",\"onSave\"],[[33,10],[33,3],[28,[37,4],[[30,0],[33,8]],null]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"polaris-layout/annotated-section\",\"polaris-card\",\"icon-upload\",\"appListingChangeset\",\"action\",\"mut\",\"app/info-card\",\"appChangeset\",\"onSave\",\"app/overview-card\",\"appListing\"]]",
    "moduleName": "smile-developers/templates/components/route-page/apps/app/information-tab.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});