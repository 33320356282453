define("smile-developers/templates/components/app/oauth-tab/permissions-section-description", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "LfhIk4io",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,0],[12],[1,\"These permissions determine what data your app can access and modify.\"],[13],[1,\"\\n\\n  \"],[10,0],[12],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"external\",\"text\",\"url\"],[true,\"Learn more about changing your app's OAuth permissions.\",\"https://docs.smile.io/docs/integrate-your-product/build-a-smile-app/features-and-functionality/webhooks#permissions\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"polaris-stack\",\"polaris-link\"]]",
    "moduleName": "smile-developers/templates/components/app/oauth-tab/permissions-section-description.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});