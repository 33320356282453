define("smile-developers/templates/components/app/editable-benefits-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "NzFgnbD+",
    "block": "[[[6,[39,0],null,[[\"vertical\",\"spacing\"],[true,\"tight\"]],[[\"default\"],[[[[42,[28,[37,2],[[28,[37,2],[[33,3]],null]],null],null,[[[6,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[41,[28,[37,5],[[30,2],[33,3,[\"firstObject\"]]],null],[[[1,\"        \"],[1,[28,[35,6],null,[[\"value\",\"onChange\"],[[30,2,[\"value\"]],[28,[37,7],[[33,8],[30,2],\"value\"],null]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,6],null,[[\"value\",\"connectedRight\",\"onChange\"],[[30,2,[\"value\"]],[50,\"remove-editable-benefit-button\",0,null,[[\"onClick\"],[[28,[37,7],[[33,10],[30,2]],null]]]],[28,[37,7],[[33,8],[30,2],\"value\"],null]]]]],[1,\"\\n\"]],[]]]],[]]]]]],[2]],null],[1,\"\\n  \"],[1,[28,[35,11],null,[[\"text\",\"size\",\"disabled\",\"onClick\"],[\"Add feature\",\"slim\",[33,12],[28,[37,7],[[33,13]],null]]]]],[1,\"\\n\"]],[1]]]]]],[\"stack\",\"editableBenefit\"],false,[\"polaris-stack\",\"each\",\"-track-array\",\"editableBenefits\",\"if\",\"eq\",\"polaris-text-field\",\"perform\",\"updateBenefit\",\"component\",\"removeBenefit\",\"polaris-button\",\"isAddBenefitDisabled\",\"addBenefit\"]]",
    "moduleName": "smile-developers/templates/components/app/editable-benefits-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});