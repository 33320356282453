define("smile-developers/helpers/polaris-select/is-group", ["exports", "@smile-io/ember-smile-polaris/helpers/polaris-select/is-group"], function (_exports, _isGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _isGroup.default;
    }
  });
  Object.defineProperty(_exports, "polarisSelectIsGroup", {
    enumerable: true,
    get: function get() {
      return _isGroup.polarisSelectIsGroup;
    }
  });
});