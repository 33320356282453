define("smile-developers/serializers/application", ["exports", "active-model-adapter", "ember-data"], function (_exports, _activeModelAdapter, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(EmbeddedRecordsMixin);

  _exports.default = _default;
});