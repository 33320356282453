define("smile-developers/components/polaris-drop-zone/file-upload", ["exports", "@smile-io/ember-smile-polaris/components/polaris-drop-zone/file-upload"], function (_exports, _fileUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _fileUpload.default;
    }
  });
});