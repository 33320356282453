define("smile-developers/routes/auth/recover", ["exports", "smile-developers/routes/unauthenticated"], function (_exports, _unauthenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _unauthenticated.default.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('email', this.controllerFor('auth').get('email'));
    }
  });

  _exports.default = _default;
});