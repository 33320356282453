define("smile-developers/templates/components/settings-page", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ruAsT7rH",
    "block": "[[[41,[28,[37,1],[[33,2],[33,3]],null],[[[44,[[28,[37,5],[[33,2],[33,6]],null]],[[[41,[30,1],[[[44,[[28,[37,5],[[33,3],[33,7]],null]],[[[41,[30,2],[[[6,[39,8],null,[[\"title\",\"class\",\"primaryAction\"],[\"Settings\",[33,9],[28,[37,10],null,[[\"text\",\"loading\",\"disabled\",\"onAction\"],[\"Save\",[33,11,[\"isRunning\"]],[28,[37,12],[[28,[37,1],[[30,1,[\"isPristine\"]],[30,2,[\"isPristine\"]]],null],[30,1,[\"isInvalid\"]],[30,2,[\"isInvalid\"]]],null],[28,[37,13],[[33,11],[30,1],[30,2]],null]]]]]],[[\"default\"],[[[[6,[39,14],null,[[\"onSubmit\"],[[28,[37,13],[[33,11],[30,1],[30,2]],null]]],[[\"default\"],[[[[6,[39,15],null,null,[[\"default\"],[[[[6,[30,3,[\"annotatedSection\"]],null,[[\"title\"],[\"Account information\"]],[[\"default\"],[[[[1,\"              \"],[1,[28,[35,16],null,[[\"partnerAccount\"],[[30,1]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,3,[\"annotatedSection\"]],null,[[\"title\"],[\"Contact information\"]],[[\"default\"],[[[[1,\"              \"],[1,[28,[35,17],null,[[\"partnerUser\"],[[30,2]]]]],[1,\"\\n\"]],[]]]]]],[3]]]]]],[]]]]]],[]]]]]],[]],null]],[2]]]],[]],null]],[1]]]],[]],null]],[\"partnerAccountChangeset\",\"partnerUserChangeset\",\"layout\"],false,[\"if\",\"and\",\"partnerAccount\",\"partnerUser\",\"let\",\"changeset\",\"partnerAccountValidations\",\"partnerUserValidations\",\"polaris-page\",\"styleNamespace\",\"hash\",\"savePartnerAccountAndUser\",\"or\",\"perform\",\"polaris-form\",\"polaris-layout\",\"partner-account-form-card\",\"partner-user-form-card\"]]",
    "moduleName": "smile-developers/templates/components/settings-page.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});