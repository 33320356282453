define("smile-developers/utils/parse-app-version", ["exports", "ember-smile-core/utils/parse-app-version"], function (_exports, _parseAppVersion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _parseAppVersion.default;
    }
  });
});