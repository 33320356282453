define("smile-developers/templates/resources", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "t7mcUEYb",
    "block": "[[[6,[39,0],null,[[\"title\"],[\"Resources\"]],[[\"default\"],[[[[6,[39,1],null,null,[[\"default\"],[[[[6,[30,1,[\"annotatedSection\"]],null,[[\"title\"],[\"Manager\"]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,2],null,[[\"manager\"],[[33,3]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"annotatedSection\"]],null,[[\"title\"],[\"Priority pages\"]],[[\"default\"],[[[[1,\"      \"],[1,[34,4]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"annotatedSection\"]],null,[[\"title\",\"description\"],[\"Why rewards?\",\"Start the conversation with your clients about the importance of building their brand community of emotionally connected, loyal customers.\"]],[[\"default\"],[[[[1,\"      \"],[1,[34,5]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"annotatedSection\"]],null,[[\"title\",\"description\"],[\"Why Smile?\",\"If your clients are ready for rewards, they're ready for Smile! Show them what Smile can do, and the results it can produce for brands just like them.\"]],[[\"default\"],[[[[1,\"      \"],[1,[34,6]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"annotatedSection\"]],null,[[\"title\",\"description\"],[\"Launching on Smile\",\"Understand what you can do to help your clients launch the best rewards program possible: from strategy to design.\"]],[[\"default\"],[[[[1,\"      \"],[1,[34,7]],[1,\"\\n\"]],[]]]]]],[1]]]]]],[]]]]]],[\"layout\"],false,[\"polaris-page\",\"polaris-layout\",\"manager-profile-card\",\"manager\",\"resources/priority-pages\",\"resources/why-rewards\",\"resources/why-smile\",\"resources/launching-on-smile\"]]",
    "moduleName": "smile-developers/templates/resources.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});