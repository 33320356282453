define("smile-developers/models/new-billing-plan", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    billingProduct: (0, _model.belongsTo)('billing-product', {
      async: false
    })
  });

  _exports.default = _default;
});