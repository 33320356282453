define("smile-developers/components/polaris-empty-state/details", ["exports", "@smile-io/ember-smile-polaris/components/polaris-empty-state/details"], function (_exports, _details) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _details.default;
    }
  });
});