define("smile-developers/adapters/partner-user", ["exports", "smile-developers/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * Allows using /v1/partner_users/me to fetch the current user
     */
    urlForQueryRecord: function urlForQueryRecord(query) {
      if (query.me) {
        delete query.me;
        return "".concat(this._super.apply(this, arguments), "/me");
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});