define("smile-developers/templates/managed-accounts/account/login", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "AN5BS/2k",
    "block": "[[[1,[28,[35,0],null,[[\"autoLogin\",\"accountId\"],[true,[33,1]]]]],[1,\"\\n\"]],[],false,[\"account/login-provider\",\"accountId\"]]",
    "moduleName": "smile-developers/templates/managed-accounts/account/login.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});