define("smile-developers/components/polaris-resource-list/loading-overlay", ["exports", "@smile-io/ember-smile-polaris/components/polaris-resource-list/loading-overlay"], function (_exports, _loadingOverlay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _loadingOverlay.default;
    }
  });
});