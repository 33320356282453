define("smile-developers/components/polaris-button-group/item", ["exports", "@smile-io/ember-smile-polaris/components/polaris-button-group/item"], function (_exports, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _item.default;
    }
  });
});