define("smile-developers/templates/components/app/features-tab/activity-section-description", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gs//SwKB",
    "block": "[[[10,0],[12],[1,\"Integrate Smile with an eCommerce site running on any technology.\"],[13],[1,\"\\n\"],[10,0],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"external\",\"text\",\"url\"],[true,\"Learn more about activities\",\"https://docs.smile.io/docs/integrate-your-product/build-a-smile-app/features-and-functionality/activities\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"polaris-link\"]]",
    "moduleName": "smile-developers/templates/components/app/features-tab/activity-section-description.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});