define("smile-developers/components/polaris-modals/delete-activity-definitions-before-permission", ["exports", "ember-smile-core/components/polaris-modals/base", "smile-developers/models/oauth2-permission"], function (_exports, _base, _oauth2Permission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    activityWritePermission: _oauth2Permission.activityWritePermission
  });

  _exports.default = _default;
});