define("smile-developers/adapters/application", ["exports", "ember-smile-core/adapters/smile-application", "@ember/object", "@ember/object/computed", "@ember/utils", "@ember/service", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _smileApplication, _object, _computed, _utils, _service, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _smileApplication.default.extend(_dataAdapterMixin.default, {
    config: (0, _service.inject)(),
    session: (0, _service.inject)(),
    host: (0, _computed.reads)('config.smile.apiHost'),
    namespace: (0, _computed.reads)('config.smile.apiNamespace'),
    headers: (0, _object.computed)({
      get: function get() {
        return {
          'Smile-Client': this.config.get('modulePrefix')
        };
      }
    }),
    authorize: function authorize(xhr) {
      var _this$get = this.get('session.data.authenticated'),
          authenticationToken = _this$get.authentication_token;

      if ((0, _utils.isPresent)(authenticationToken)) {
        xhr.setRequestHeader('Authorization', "Token ".concat(authenticationToken));
      }
    }
  });

  _exports.default = _default;
});