define("smile-developers/transforms/object", ["exports", "ember-data", "@ember/utils"], function (_exports, _emberData, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = _emberData.default.Transform;

  var _default = Transform.extend({
    deserialize: function deserialize(serialized) {
      return (0, _utils.isNone)(serialized) ? {} : serialized;
    },
    serialize: function serialize(deserialized) {
      return (0, _utils.isNone)(deserialized) ? {} : deserialized;
    }
  });

  _exports.default = _default;
});