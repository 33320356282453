define("smile-developers/templates/components/oauth2-permission-group/pick-permission", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "9H55kwvq",
    "block": "[[[6,[39,0],null,[[\"distribution\"],[\"equalSpacing\"]],[[\"default\"],[[[[6,[39,0],null,[[\"vertical\",\"spacing\"],[true,\"tight\"]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,1],null,[[\"variation\",\"text\"],[\"strong\",[33,2,[\"name\"]]]]]],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"variation\",\"text\"],[\"subdued\",[28,[37,3],[\",\",[33,2,[\"permissions\"]]],null]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n  \"],[1,[28,[35,4],null,[[\"options\",\"value\",\"onChange\"],[[33,5],[33,6,[\"optionName\"]],[28,[37,7],[[30,0],\"selectPermission\"],null]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"polaris-stack\",\"polaris-text-style\",\"oauth2PermissionGroup\",\"join\",\"polaris-select\",\"oauth2PermissionOptions\",\"selectedPermission\",\"action\"]]",
    "moduleName": "smile-developers/templates/components/oauth2-permission-group/pick-permission.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});