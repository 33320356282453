define("smile-developers/components/resources/why-smile", ["exports", "@ember/component", "smile-developers/utils/partner-resources"], function (_exports, _component, _partnerResources) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',

    /**
     * List of partner resources links.
     */
    partnerResources: _partnerResources.default
  });

  _exports.default = _default;
});