define("smile-developers/validations/oauth2-application", ["exports", "smile-developers/validators/array", "ember-changeset-validations/validators", "smile-developers/utils/regex"], function (_exports, _array, _validators, _regex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    redirectUris: (0, _array.default)((0, _validators.validatePresence)({
      presence: true,
      description: 'Whitelisted redirection URL(s)'
    }), (0, _validators.validateFormat)({
      regex: _regex.oauthUrlFormat,
      description: 'Whitelisted redirection URL(s)',
      message: "{description} must be valid URL(s) (must have a protocol, e.g. 'https://')"
    }))
  };
  _exports.default = _default;
});