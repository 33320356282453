define("smile-developers/routes/auth", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        this.send('resetAuthData');
      }
    },
    actions: {
      persistAuthData: function persistAuthData() {
        var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        this.controller.setProperties(data);
      },
      resetAuthData: function resetAuthData() {
        this.controller.setProperties({
          email: null,
          hasRecoveredPassword: false,
          hasResetPassword: false,
          isResetPasswordTokenExpired: false
        });
      }
    }
  });

  _exports.default = _default;
});