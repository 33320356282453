define("smile-developers/templates/components/polaris-table/cell/account-login-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "bdE1mtb6",
    "block": "[[[6,[39,0],null,[[\"account\"],[[28,[37,1],[[33,2],\"content\"],null]]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,3],null,[[\"text\",\"loading\",\"onClick\"],[\"Login\",[30,1,[\"isRunning\"]],[28,[37,4],[[30,1,[\"tasks\",\"login\"]]],null]]]]],[1,\"\\n\"]],[1]]]]]],[\"accountLoginProvider\"],false,[\"account/login-provider\",\"get\",\"row\",\"polaris-button\",\"perform\"]]",
    "moduleName": "smile-developers/templates/components/polaris-table/cell/account-login-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});