define("smile-developers/routes/apps/app", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    model: function model(params) {
      var includes = ['app_listing', 'oauth2_application.oauth2_permissions', 'activity_definitions', 'webhooks'];
      return this.store.findRecord('app', params.app_id, {
        include: includes.join(','),
        reload: true
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('app', model);
    }
  });

  _exports.default = _default;
});