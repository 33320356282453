define("smile-developers/initializers/datadog", ["exports", "smile-developers/config/environment", "@datadog/browser-rum"], function (_exports, _environment, _browserRum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    if (_environment.default.datadog.enabled) {
      _browserRum.datadogRum.init({
        applicationId: _environment.default.datadog.applicationId,
        clientToken: _environment.default.datadog.clientToken,
        site: 'datadoghq.com',
        service: _environment.default.modulePrefix,
        env: _environment.default.smile.env,
        version: application.version,
        trackInteractions: true,
        sampleRate: 100,
        allowedTracingOrigins: [_environment.default.smile.apiHost]
      });
    }
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});