define("smile-developers/components/collapsible-list", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * The number of items to display when the
     * list is collapsed
     *
     * @type {Number}
     * @default 4
     * @public
     */
    hideAfter: 4,

    /**
     * List of objects to display as links
     *
     * Format:
     * { text, url }
     *
     * @type {Object[]}
     * @default null
     * @public
     */
    listItems: null,

    /**
     * Descriptor for triggler title. This gets prefixed
     * with either "Show all" or "Show less".
     *
     * @type {String}
     * @default ''
     * @public
     */
    triggerTitle: '',

    /**
     * Whether or not the component will render the
     * full list.
     *
     * @private
     */
    shouldDisplayAllItems: false,

    /**
     * Whether or not there are enough items to require
     * showing the collapse trigger
     *
     * @private
     */
    shouldRenderTrigger: (0, _object.computed)('hideAfter', 'listItems', function () {
      var hideAfter = this.hideAfter,
          listItems = this.listItems;
      return listItems.get('length') > hideAfter;
    }),

    /**
     * The items to display
     *
     * @private
     */
    items: (0, _object.computed)('shouldDisplayAllItems', 'listItems', 'hideAfter', function () {
      var shouldDisplayAllItems = this.shouldDisplayAllItems,
          listItems = this.listItems,
          hideAfter = this.hideAfter;

      if (shouldDisplayAllItems) {
        return listItems;
      }

      return listItems.slice(0, hideAfter);
    }),

    /**
     * The text rendered in the collapse trigger
     *
     * @private
     */
    displayTriggerText: (0, _object.computed)('triggerTitle', 'shouldDisplayAllItems', function () {
      var triggerTitle = this.triggerTitle,
          shouldDisplayAllItems = this.shouldDisplayAllItems;
      var prefix = shouldDisplayAllItems ? 'Show less' : 'Show more';
      return "".concat(prefix, " ").concat(triggerTitle);
    })
  });

  _exports.default = _default;
});