define("smile-developers/models/billing-subscription-default", ["exports", "@ember-data/model", "@ember/object/computed"], function (_exports, _model, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * Relationships
     */
    newBillingPlan: (0, _model.belongsTo)('new-billing-plan', {
      async: false
    }),

    /**
     * Computed properties
     */
    isBaseDefault: (0, _computed.readOnly)('newBillingPlan.billingProduct.isPlanType')
  });

  _exports.default = _default;
});