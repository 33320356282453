define("smile-developers/components/polaris-modals/test-app-info", ["exports", "ember-smile-core/components/polaris-modals/base", "@ember/service", "@ember/object/computed"], function (_exports, _base, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    config: (0, _service.inject)(),
    sesh: (0, _service.inject)(),

    /**
     * App listing to link to.
     * @type {Model}
     * @public
     */
    appListing: (0, _computed.readOnly)('model')
  });

  _exports.default = _default;
});