define("smile-developers/services/feature-rollouts", ["exports", "ember-feature-flags/services/features", "@ember/service"], function (_exports, _features, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _features.default.extend({
    config: (0, _service.inject)(),
    // Overwrites addon to have appropriate logging messsage
    _logFeatureFlagMiss: function _logFeatureFlagMiss(feature) {
      // eslint-disable-next-line no-console
      if (console && console.info) {
        // eslint-disable-next-line no-console
        console.info('Rollout flag off:', feature);
      }
    }
  });

  _exports.default = _default;
});