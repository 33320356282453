define("smile-developers/templates/components/app/info-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "g7X4ENQw",
    "block": "[[[6,[39,0],null,[[\"sectioned\"],[true]],[[\"default\"],[[[[6,[39,1],null,[[\"onSubmit\"],[[28,[37,2],[[30,0],[33,3]],null]]],[[\"default\"],[[[[6,[39,4],null,null,[[\"default\"],[[[[1,\"      \"],[1,[28,[35,5],null,[[\"label\",\"placeholder\",\"value\",\"error\",\"onChange\"],[\"App name\",\"New app name\",[33,6,[\"name\"]],[33,6,[\"error\",\"name\",\"validation\",\"firstObject\"]],[28,[37,2],[[30,0],[28,[37,7],[[33,6,[\"name\"]]],null]],null]]]]],[1,\"\\n\\n      \"],[1,[28,[35,5],null,[[\"label\",\"placeholder\",\"value\",\"onChange\"],[\"Website\",\"e.g. https://example.com\",[33,8,[\"websiteUrl\"]],[28,[37,2],[[30,0],[28,[37,7],[[33,8,[\"websiteUrl\"]]],null]],null]]]]],[1,\"\\n\"]],[]]]]]],[]]]]]],[]]]]]],[],false,[\"polaris-card\",\"polaris-form\",\"action\",\"onSave\",\"polaris-form-layout\",\"polaris-text-field\",\"appChangeset\",\"mut\",\"appListingChangeset\"]]",
    "moduleName": "smile-developers/templates/components/app/info-card.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});