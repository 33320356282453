define("smile-developers/models/app", ["exports", "ember-data", "@ember/object/computed"], function (_exports, _emberData, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    /**
     * Attributes
     */
    name: attr('string'),
    type: attr('string'),
    oauthConnectUrl: attr('string'),
    installCount: attr('number'),

    /**
     * Relationships
     */
    appListing: belongsTo('app-listing', {
      async: false
    }),
    oauth2Application: belongsTo('oauth2-application', {
      async: false
    }),
    activityDefinitions: hasMany('activity-definition', {
      async: false
    }),
    webhooks: hasMany('webhook', {
      async: false
    }),

    /**
     * Computed
     */
    webhook: (0, _computed.readOnly)('webhooks.firstObject')
  });

  _exports.default = _default;
});