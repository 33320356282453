define("smile-developers/routes/auth/auto-login", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    session: (0, _service.inject)(),
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments); // If we are already authenticated
      //  - abort transition (no need to continue here until we reload the page)
      //  - invalidate the session
      //  - reload page to still auto-login, otherwise default behaviour for
      //    `ember-simple-auth` is to redirect to login page
      // NOTE: Technically, we can just re-authenticate even if the session is
      // authenticated, but that breaks ember-simple-auth and causes `sessionAuthenticated`
      // event to not be triggered, resulting in having a tab not refresh if in a different
      // tab we auto-login into a different account


      if (this.session.isAuthenticated) {
        transition.abort();
        return this.session.invalidate().then(function () {
          return transition.send('reloadPage');
        });
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.autoLogin.perform();
    }
  });

  _exports.default = _default;
});