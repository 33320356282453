define("smile-developers/controllers/application", ["exports", "@ember/controller", "@ember/service", "@ember/object/computed"], function (_exports, _controller, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    media: (0, _service.inject)(),
    sesh: (0, _service.inject)(),
    isTopBarVisible: false,
    isNavEnabled: false,
    isNavLocked: (0, _computed.readOnly)('media.isMobile'),
    partnerUser: (0, _computed.reads)('sesh.partnerUser'),
    partnerAccount: (0, _computed.reads)('sesh.partnerAccount')
  });

  _exports.default = _default;
});