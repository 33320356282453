define("smile-developers/components/manager-profile-card", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',

    /**
     * The partner manager (admin model)
     *
     * @type {Model}
     * @public
     */
    manager: null
  });

  _exports.default = _default;
});