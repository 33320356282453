define("smile-developers/utils/regex", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.oauthUrlFormat = void 0;
  // This is a tweaked version of the URL regex used internally for URLs
  // by `ember-changeset-validations`. We've modified it here to:
  // - make the protocol mandatory;
  // - allow one-word domains (e.g. `localhost`)
  var oauthUrlFormat = /[A-Za-z]+:(\/{2})[a-zA-Z0-9][a-zA-Z-0-9]*(\.[\w-]+)*([\w.,@?^=%&amp;:\/~+#-{}]*[\w@?^=%&amp;\/~+#-{}])??/; //eslint-disable-line no-useless-escape

  _exports.oauthUrlFormat = oauthUrlFormat;
});