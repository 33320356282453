define("smile-developers/components/polaris-resource-list/filter-control/filter-value-selector", ["exports", "@smile-io/ember-smile-polaris/components/polaris-resource-list/filter-control/filter-value-selector"], function (_exports, _filterValueSelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _filterValueSelector.default;
    }
  });
});