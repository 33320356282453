define("smile-developers/models/partner-user", ["exports", "ember-data", "@ember/object/computed", "@ember/object"], function (_exports, _emberData, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    /**
     * Attributes
     */
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    password: attr('string'),
    passwordConfirmation: attr('string'),

    /**
     * Relationships
     */
    partnerAccounts: hasMany('partner-account', {
      async: false
    }),

    /**
     * Computed properties
     */
    partnerAccount: (0, _computed.alias)('partnerAccounts.firstObject'),
    fullName: (0, _object.computed)('firstName', 'lastName', function () {
      return "".concat(this.firstName, " ").concat(this.lastName).trim();
    }).readOnly(),
    initials: (0, _object.computed)('firstName', 'lastName', function () {
      return "".concat(this.firstName[0]).concat(this.lastName[0]);
    }).readOnly()
  });

  _exports.default = _default;
});