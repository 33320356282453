define("smile-developers/controllers/resources", ["exports", "@ember/controller", "@ember/service", "@ember/object/computed"], function (_exports, _controller, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    sesh: (0, _service.inject)(),
    manager: (0, _computed.reads)('sesh.partnerAccount.manager')
  });

  _exports.default = _default;
});