define("smile-developers/validations/app-listing", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    documentationUrl: (0, _validators.validateFormat)({
      type: 'url',
      allowBlank: true,
      description: 'Documentation URL'
    })
  };
  _exports.default = _default;
});