define("smile-developers/routes/authenticated", ["exports", "@ember/routing/route", "ember-simple-auth/mixins/authenticated-route-mixin", "@ember/service"], function (_exports, _route, _authenticatedRouteMixin, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_authenticatedRouteMixin.default, {
    sesh: (0, _service.inject)(),

    /**
     * The route to transition to for authentication. The AuthenticatedRouteMixin
     * will transition to this route when a route that implements the mixin is
     * accessed when the route is not authenticated.
     *
     * @type {String}
     */
    authenticationRoute: 'auth.login',
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      return this.get('sesh.fetchPartnerUser').perform();
    },
    activate: function activate() {
      this._super.apply(this, arguments);

      this.send('enableNav');
      this.send('enableTopBar');
    }
  });

  _exports.default = _default;
});