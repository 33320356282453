define("smile-developers/components/polaris-modals/save-oauth2-application-with-permission-changes", ["exports", "ember-smile-core/components/polaris-modals/base", "@ember/object/computed"], function (_exports, _base, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    /**
     * @type {String}
     * @public
     */
    appName: (0, _computed.readOnly)('modalParams.appName'),

    /**
     * @type {Boolean}
     * @public
     */
    isRunning: (0, _computed.readOnly)('modalParams.isRunning')
  });

  _exports.default = _default;
});