define("smile-developers/templates/components/resources/why-rewards", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "w1QmK8AT",
    "block": "[[[6,[39,0],null,[[\"title\",\"sectioned\"],[\"Rewards resources\",true]],[[\"default\"],[[[[6,[39,1],null,null,[[\"default\"],[[[[42,[28,[37,3],[[28,[37,3],[[33,4,[\"rewardsResources\"]]],null]],null],null,[[[6,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[1,\"        \"],[1,[28,[35,5],null,[[\"external\",\"url\",\"text\"],[true,[30,2,[\"url\"]],[30,2,[\"text\"]]]]]],[1,\"\\n\"]],[]]]]]],[2]],null]],[1]]]]]],[]]]]],[1,\"\\n\"],[6,[39,0],null,[[\"title\",\"sectioned\"],[\"Industry guides\",true]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,6],null,[[\"triggerTitle\",\"listItems\"],[\"industries\",[33,4,[\"industryGuides\"]]]]]],[1,\"\\n\"]],[]]]]]],[\"list\",\"resource\"],false,[\"polaris-card\",\"polaris-list\",\"each\",\"-track-array\",\"partnerResources\",\"polaris-link\",\"collapsible-list\"]]",
    "moduleName": "smile-developers/templates/components/resources/why-rewards.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});