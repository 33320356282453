define("smile-developers/templates/components/oauth2-application/api-permissions-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qQT4ETlk",
    "block": "[[[41,[33,1],[[[6,[39,2],null,[[\"sectioned\"],[true]],[[\"default\"],[[[[1,\"    \"],[1,[34,3]],[1,\"\\n\"]],[]]]]]],[]],[[[6,[39,2],null,[[\"title\"],[\"API permissions\"]],[[\"default\"],[[[[42,[28,[37,5],[[28,[37,5],[[33,6]],null]],null],null,[[[6,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[1,\"        \"],[1,[28,[35,7],null,[[\"oauth2PermissionGroup\",\"oauth2Application\",\"onSetPermission\"],[[30,2],[33,8],[28,[37,9],[[30,0],\"setPermission\"],null]]]]],[1,\"\\n\"]],[]]]]]],[2]],null],[1,\"\\n\"],[6,[39,10],[[33,11]],null,[[\"default\"],[[[[42,[28,[37,5],[[28,[37,5],[[33,12]],null]],null],null,[[[6,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[1,\"          \"],[1,[28,[35,7],null,[[\"oauth2PermissionGroup\",\"oauth2Application\",\"onSetPermission\"],[[30,3],[33,8],[28,[37,9],[[30,0],\"setPermission\"],null]]]]],[1,\"\\n\"]],[]]]]]],[3]],null]],[]]]]],[1,\"\\n\"],[41,[33,12],[[[6,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[1,\"        \"],[1,[28,[35,13],null,[[\"isToggled\",\"text\",\"onToggle\"],[[33,11],[52,[33,11],\"Collapse disabled API permissions\",\"Show disabled API permissions\"],[28,[37,9],[[30,0],[28,[37,14],[\"shouldShowDisabledPermissions\",[30,0]],null]],null]]]]],[1,\"\\n\"]],[]]]]]],[]],null]],[1]]]]]],[]]]],[\"card\",\"oauth2PermissionGroup\",\"oauth2PermissionGroup\"],false,[\"if\",\"isLoading\",\"polaris-card\",\"polaris-skeleton-body-text\",\"each\",\"-track-array\",\"enabledOauth2PermissionGroups\",\"oauth2-permission-group/pick-permission\",\"oauth2ApplicationChangeset\",\"action\",\"polaris-liquid-if\",\"shouldShowDisabledPermissions\",\"disabledOauth2PermissionGroups\",\"toggleable-link\",\"toggle\"]]",
    "moduleName": "smile-developers/templates/components/oauth2-application/api-permissions-card.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});