define("smile-developers/components/webhook-event-definition/dropdown-item", ["exports", "@ember/component", "@ember/debug"], function (_exports, _component, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @type {DS.Model}
     * @public
     */
    webhookEventDefinition: null,
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(this.webhookEventDefinition) && (0, _debug.assert)('[webhook-event-definition/dropdown-item] missing required "webhookEventDefinition" attribute!', this.webhookEventDefinition));
    }
  });

  _exports.default = _default;
});