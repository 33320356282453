define("smile-developers/templates/components/resources/priority-pages", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "tGIx3gsw",
    "block": "[[[6,[39,0],null,[[\"title\",\"sectioned\"],[\"General resources\",true]],[[\"default\"],[[[[6,[39,1],null,null,[[\"default\"],[[[[42,[28,[37,3],[[28,[37,3],[[33,4,[\"generalResources\"]]],null]],null],null,[[[6,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[1,\"        \"],[1,[28,[35,5],null,[[\"external\",\"url\",\"text\"],[true,[30,2,[\"url\"]],[30,2,[\"text\"]]]]]],[1,\"\\n\"]],[]]]]]],[2]],null]],[1]]]]]],[]]]]],[1,\"\\n\"],[6,[39,0],null,[[\"title\",\"sectioned\"],[\"Technical documentation\",true]],[[\"default\"],[[[[42,[28,[37,3],[[28,[37,3],[[33,4,[\"technicalDocumentation\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,5],null,[[\"external\",\"url\",\"text\"],[true,[30,3,[\"url\"]],[30,3,[\"text\"]]]]]],[1,\"\\n\"]],[3]],null]],[]]]]]],[\"list\",\"resource\",\"resource\"],false,[\"polaris-card\",\"polaris-list\",\"each\",\"-track-array\",\"partnerResources\",\"polaris-link\"]]",
    "moduleName": "smile-developers/templates/components/resources/priority-pages.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});