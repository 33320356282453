define("smile-developers/templates/context-consumer-component", ["exports", "ember-context/templates/context-consumer-component"], function (_exports, _contextConsumerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _contextConsumerComponent.default;
    }
  });
});