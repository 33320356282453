define("smile-developers/utils/models/serialize-and-push", ["exports", "ember-inflector", "smile-developers/utils/models/build-url"], function (_exports, _emberInflector, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.serializeAndPush = serializeAndPush;

  function serializeAndPush(response) {
    var store = (0, _buildUrl.getStoreFromRecord)(this); // Response is always of the form { underscored_model_name(s): Object | Array }
    // so we grab the model based on response (because some endpoints return a different resource)
    // TODO stop returning a different resource, most of the times it's just an association

    var modelNameUnderscored = (0, _emberInflector.singularize)(Object.keys(response)[0]);
    var recordClass = store.modelFor(modelNameUnderscored);
    var modelName = (0, _buildUrl.getModelName)(recordClass);
    var serializer = store.serializerFor(modelName);
    var normalized;

    if (Object.prototype.hasOwnProperty.call(response, (0, _emberInflector.pluralize)(modelNameUnderscored))) {
      var doc = response;
      normalized = serializer.normalizeArrayResponse(store, recordClass, doc, null, 'findAll');
    } else {
      var _doc = response;
      normalized = serializer.normalizeSingleResponse(store, recordClass, _doc, "".concat(_doc[modelNameUnderscored].id || '(unknown)'), 'findRecord');
    }

    return store.push(normalized);
  }
});