define("smile-developers/templates/components/app/data-table", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "IU8DoE/Y",
    "block": "[[[8,[39,0],null,[[\"@columnContentTypes\",\"@headings\",\"@rows\"],[[30,0,[\"columnContentTypes\"]],[30,0,[\"headings\"]],[30,0,[\"rows\"]]]],null],[1,\"\\n\"]],[],false,[\"polaris-data-table\"]]",
    "moduleName": "smile-developers/templates/components/app/data-table.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});