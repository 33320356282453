define("smile-developers/templates/components/manager-profile-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZLTJZ28P",
    "block": "[[[6,[39,0],null,[[\"sectioned\",\"class\"],[true,[33,1]]],[[\"default\"],[[[[6,[39,2],null,[[\"alignment\"],[\"center\"]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,3],null,[[\"size\",\"avatarSourcePath\",\"customer\",\"name\"],[\"large\",\"/assets/icons/polaris\",true,[33,4,[\"fullName\"]]]]]],[1,\"\\n\\n\"],[6,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[1,\"      \"],[1,[28,[35,5],null,[[\"text\"],[[33,6,[\"fullName\"]]]]]],[1,\"\\n\\n      \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,7],null,[[\"external\",\"text\",\"url\"],[true,[33,6,[\"email\"]],[28,[37,8],[\"mailto:\",[33,6,[\"email\"]]],null]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]]]]],[1]]]]]],[]]]]]],[\"stack\"],false,[\"polaris-card\",\"styleNamespace\",\"polaris-stack\",\"polaris-avatar\",\"partnerUser\",\"polaris-heading\",\"manager\",\"polaris-link\",\"concat\"]]",
    "moduleName": "smile-developers/templates/components/manager-profile-card.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});