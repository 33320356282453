define("smile-developers/models/oauth2-permission-group", ["exports", "ember-data", "@ember/object/computed"], function (_exports, _emberData, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    /**
     * Attributes
     */
    name: attr('string'),
    description: attr('string'),

    /**
     * Associations
     */
    oauth2Permissions: hasMany('oauth2-permission', {
      async: false
    }),

    /**
     * Cps
     */

    /**
     * An array of the names of the oauth2Permissions that
     * belong to this group
     * @type {String[]}
     * @public
     */
    permissions: (0, _computed.mapBy)('oauth2Permissions', 'name')
  });

  _exports.default = _default;
});