define("smile-developers/routes/application", ["exports", "@ember/routing/route", "@ember/service", "ember-simple-auth/mixins/application-route-mixin", "ember-smile-core/utils/external-links"], function (_exports, _route, _service, _applicationRouteMixin, _externalLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_applicationRouteMixin.default, {
    errorHandler: (0, _service.inject)(),
    sesh: (0, _service.inject)(),

    /**
     * The route to transition to after successful authentication.
     * @type {String}
     */
    routeAfterAuthentication: 'managed-accounts',
    actions: {
      invalidateSession: function invalidateSession() {
        this.session.invalidate();
      },
      enableNav: function enableNav() {
        this.set('controller.isNavEnabled', true);
      },
      disableNav: function disableNav() {
        this.set('controller.isNavEnabled', false);
      },
      enableTopBar: function enableTopBar() {
        this.set('controller.isTopBarVisible', true);
      },
      disableTopBar: function disableTopBar() {
        this.set('controller.isTopBarVisible', false);
      },
      // Reloads the current page
      reloadPage: function reloadPage() {
        window.location.reload();
      },
      goHome: function goHome() {
        this.transitionTo(this.routeAfterAuthentication);
      },
      goLogin: function goLogin() {
        this.transitionTo('auth.login');
      },
      redirectToExternal: _externalLinks.openExternalLink,

      /*
       * Catch errors.
       *
       * Any errors thrown by a rejecting promise in one of the model hooks:
       * `beforeModel`, `model` or `afterModel` will trigger this `error` event
       * which we use to redirect to the `error` page.
       */
      error: function error(_error
      /* , transition */
      ) {
        return this.errorHandler.handleRouteError(_error);
      }
    }
  });

  _exports.default = _default;
});