define("smile-developers/components/app/oauth-card", ["exports", "@ember/component", "@ember/utils", "rsvp"], function (_exports, _component, _utils, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',

    /**
     * Callback triggered on submitting the form
     *
     * @type {Function}
     * @public
     */
    onSave: _rsvp.resolve,
    actions: {
      setRedirectUris: function setRedirectUris(changeset, value) {
        changeset.setProperties({
          // This splits the string by any whitespace chars
          redirectUris: value.match(/\S+/g) || [],
          redirectUrisForTextareaField: value
        }); // We need to manually handle this, because we want to validate an array
        // of URLs - `redirectUris`, but we need to display in a text area field,
        // which works with strings (using a CP `redirectUrisForTextareaField`),
        // so we validate the original array value `redirectUris`, but we show the
        // errors on the used value in the form - `redirectUrisForTextareaField`.

        var errors = changeset.get('errors');
        var redirectUrisError = errors.filterBy('key', 'redirectUris').get('firstObject');

        if ((0, _utils.isPresent)(redirectUrisError)) {
          changeset.pushErrors('redirectUrisForTextareaField', redirectUrisError.validation);
        }
      }
    }
  });

  _exports.default = _default;
});