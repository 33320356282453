define("smile-developers/components/polaris-resource-list/checkable-button", ["exports", "@smile-io/ember-smile-polaris/components/polaris-resource-list/checkable-button"], function (_exports, _checkableButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _checkableButton.default;
    }
  });
});