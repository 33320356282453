define("smile-developers/helpers/is-validating-changeset", ["exports", "ember-smile-core/helpers/is-validating-changeset"], function (_exports, _isValidatingChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _isValidatingChangeset.default;
    }
  });
  Object.defineProperty(_exports, "isValidatingChangeset", {
    enumerable: true,
    get: function get() {
      return _isValidatingChangeset.isValidatingChangeset;
    }
  });
});