define("smile-developers/serializers/webhook-event-definition", ["exports", "smile-developers/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      requiredPermissions: {
        deserialize: 'records',
        serialize: false
      }
    }
  });

  _exports.default = _default;
});