define("smile-developers/templates/components/partner-user-form-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Li/7SkBk",
    "block": "[[[6,[39,0],null,[[\"sectioned\"],[true]],[[\"default\"],[[[[6,[39,1],null,null,[[\"default\"],[[[[6,[30,1,[\"group\"]],null,null,[[\"default\"],[[[[1,\"      \"],[1,[28,[35,2],null,[[\"label\",\"value\",\"error\",\"onChange\"],[\"First name\",[33,3,[\"firstName\"]],[33,3,[\"error\",\"firstName\",\"validation\",\"firstObject\"]],[28,[37,4],[[30,0],[28,[37,5],[[33,3,[\"firstName\"]]],null]],null]]]]],[1,\"\\n\\n      \"],[1,[28,[35,2],null,[[\"label\",\"value\",\"error\",\"onChange\"],[\"Last name\",[33,3,[\"lastName\"]],[33,3,[\"error\",\"lastName\",\"validation\",\"firstObject\"]],[28,[37,4],[[30,0],[28,[37,5],[[33,3,[\"lastName\"]]],null]],null]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"label\",\"type\",\"value\",\"error\",\"onChange\"],[\"Email\",\"email\",[33,3,[\"email\"]],[33,3,[\"error\",\"email\",\"validation\",\"firstObject\"]],[28,[37,4],[[30,0],[28,[37,5],[[33,3,[\"email\"]]],null]],null]]]]],[1,\"\\n\\n    \"],[1,[28,[35,2],null,[[\"label\",\"type\",\"value\",\"error\",\"onChange\"],[\"Password\",\"password\",[33,3,[\"password\"]],[33,3,[\"error\",\"password\",\"validation\",\"firstObject\"]],[28,[37,4],[[30,0],[28,[37,5],[[33,3,[\"password\"]]],null]],null]]]]],[1,\"\\n\\n    \"],[1,[28,[35,2],null,[[\"label\",\"type\",\"value\",\"error\",\"onChange\"],[\"Password Confirmation\",\"password\",[33,3,[\"passwordConfirmation\"]],[33,3,[\"error\",\"passwordConfirmation\",\"validation\",\"firstObject\"]],[28,[37,4],[[30,0],[28,[37,5],[[33,3,[\"passwordConfirmation\"]]],null]],null]]]]],[1,\"\\n\"]],[1]]]]]],[]]]]]],[\"formLayout\"],false,[\"polaris-card\",\"polaris-form-layout\",\"polaris-text-field\",\"partnerUser\",\"action\",\"mut\"]]",
    "moduleName": "smile-developers/templates/components/partner-user-form-card.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});