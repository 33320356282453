define("smile-developers/models/account", ["exports", "ember-data", "@ember/object", "@ember/object/computed"], function (_exports, _emberData, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    /**
     * Attributes
     */
    name: attr('string'),
    programStatus: attr('string'),
    // NOTE: these are actually relationships, but we only need one thing from
    // each, so no point in complicating things.
    billingPlan: attr(),

    /**
     * Relationships
     */
    users: hasMany('user', {
      async: false
    }),
    billingSubscriptions: hasMany('billing-subscription', {
      async: false
    }),

    /**
     * Computed properties
     */
    user: (0, _computed.alias)('users.firstObject'),
    chargeableSubscription: (0, _object.computed)('billingSubscriptions.@each.isChargeable', function () {
      return this.billingSubscriptions.findBy('isChargeable');
    }).readOnly(),
    programStatusToBadgeStatus: (0, _object.computed)('programStatus', function () {
      return this.programStatus === 'Live' ? 'success' : 'default';
    }),
    loginLink: (0, _object.computed)(function () {
      return "managed-accounts/".concat(this.id, "/login");
    }).readOnly()
  });

  _exports.default = _default;
});