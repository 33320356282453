define("smile-developers/validations/partner-account", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: (0, _validators.validatePresence)({
      presence: true,
      description: 'Business name'
    }),
    websiteUrl: (0, _validators.validateFormat)({
      type: 'url',
      description: 'Website'
    })
  };
  _exports.default = _default;
});