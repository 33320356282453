define("smile-developers/components/constrained-image", ["exports", "ember-smile-core/components/constrained-image"], function (_exports, _constrainedImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _constrainedImage.default;
    }
  });
});