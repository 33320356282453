define("smile-developers/templates/components/polaris-modals/save-oauth2-application-with-permission-changes", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mRx19maD",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[6,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[6,[39,1],null,[[\"alignment\"],[\"center\"]],[[\"default\"],[[[[6,[30,2,[\"item\"]],null,[[\"fill\"],[true]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,2],null,[[\"size\",\"text\"],[\"small\",[28,[37,3],[\"Edit \",[33,4]],null]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"item\"]],null,null,[[\"default\"],[[[[6,[39,5],null,[[\"class\",\"onClick\"],[\"polaris-modal-close-btn\",[28,[37,6],[[30,0],\"closeModal\"],null]]],[[\"default\"],[[[[1,\"          \"],[1,[28,[35,7],null,[[\"source\"],[\"cancel\"]]]],[1,\"\\n\"]],[]]]]]],[]]]]]],[2]]]]]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[6,[39,1],null,[[\"vertical\",\"spacing\"],[true,\"loose\"]],[[\"default\"],[[[[1,\"      \"],[1,[28,[30,3,[\"item\"]],null,[[\"text\"],[[28,[37,3],[\"You have changed the permissions for \",[33,4],\". Merchants will have to reauthorize the app before the permission changes take effect.\"],null]]]]],[1,\"\\n\\n      \"],[1,[28,[30,3,[\"item\"]],null,[[\"text\"],[\"Do you want to continue saving these changes?\"]]]],[1,\"\\n\"]],[3]]]]]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[6,[39,1],null,[[\"distribution\"],[\"trailing\"]],[[\"default\"],[[[[6,[39,8],null,null,[[\"default\"],[[[[1,\"        \"],[1,[28,[35,9],null,[[\"text\",\"disabled\",\"onClick\"],[\"Cancel\",[33,10],[28,[37,6],[[30,0],\"closeModal\"],null]]]]],[1,\"\\n\\n        \"],[1,[28,[35,9],null,[[\"primary\",\"loading\",\"text\",\"onClick\"],[true,[33,10],\"Save\",[28,[37,6],[[30,0],\"confirm\"],null]]]]],[1,\"\\n\"]],[]]]]]],[]]]]]],[]]]]]],[1]]]]]],[\"card\",\"stack\",\"stack\"],false,[\"polaris-card\",\"polaris-stack\",\"polaris-display-text\",\"concat\",\"appName\",\"polaris-link\",\"action\",\"polaris-icon\",\"polaris-button-group\",\"polaris-button\",\"isRunning\"]]",
    "moduleName": "smile-developers/templates/components/polaris-modals/save-oauth2-application-with-permission-changes.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});