define("smile-developers/components/polaris-data-table/navigation", ["exports", "@smile-io/ember-smile-polaris/components/polaris-data-table/navigation"], function (_exports, _navigation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _navigation.default;
    }
  });
});