define("smile-developers/models/app-listing", ["exports", "ember-data", "@ember/object", "@ember/string"], function (_exports, _emberData, _object, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    /**
     * Attributes
     */
    slug: attr('string'),
    submissionStatus: attr('string'),
    websiteUrl: attr('string'),
    isPublic: attr('boolean'),
    shortDescription: attr('string'),
    documentationUrl: attr('string'),
    iconUrl: attr('string'),
    customizedIconTempS3Key: attr('string'),
    customizedIconUrl: attr('string'),
    benefitsList: attr('array', {
      serializeEmptyItems: false
    }),

    /**
     * CPs
     */
    submissionStatusAsBadgeText: (0, _object.computed)('submissionStatus', function () {
      return (0, _string.capitalize)(this.submissionStatus);
    }).readOnly(),
    submissionStatusAsBadgeStatus: (0, _object.computed)('submissionStatus', function () {
      // TODO revise these once we properly handle app submissions, etc
      return this.submissionStatus === 'approved' ? 'success' : 'attention';
    }).readOnly()
  });

  _exports.default = _default;
});