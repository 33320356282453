define("smile-developers/templates/components/polaris-modals/delete-activity-definition", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "JPlojphN",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[6,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[6,[39,1],null,[[\"alignment\"],[\"center\"]],[[\"default\"],[[[[6,[30,2,[\"item\"]],null,[[\"fill\"],[true]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,2],null,[[\"size\",\"text\"],[\"small\",[28,[37,3],[\"Delete \",[33,4,[\"name\"]],\"?\"],null]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"item\"]],null,null,[[\"default\"],[[[[6,[39,5],null,[[\"class\",\"onClick\"],[\"polaris-modal-close-btn\",[28,[37,6],[[30,0],\"closeModal\"],null]]],[[\"default\"],[[[[1,\"          \"],[1,[28,[35,7],null,[[\"source\"],[\"cancel\"]]]],[1,\"\\n\"]],[]]]]]],[]]]]]],[2]]]]]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[6,[39,1],null,[[\"vertical\",\"spacing\"],[true,\"loose\"]],[[\"default\"],[[[[1,\"      \"],[10,1],[12],[1,\"\\n        Deleting this activity type will also disable it for all merchants using it in their program.\\n        \"],[1,[28,[35,8],null,[[\"variation\",\"text\"],[\"strong\",\"This cannot be reversed.\"]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]]]]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[6,[39,1],null,[[\"distribution\"],[\"trailing\"]],[[\"default\"],[[[[6,[39,9],null,null,[[\"default\"],[[[[1,\"        \"],[1,[28,[35,10],null,[[\"text\",\"disabled\",\"onClick\"],[\"Cancel\",[33,11],[28,[37,6],[[30,0],\"closeModal\"],null]]]]],[1,\"\\n\\n        \"],[1,[28,[35,10],null,[[\"primary\",\"destructive\",\"loading\",\"text\",\"onClick\"],[true,true,[33,11],\"Delete\",[28,[37,6],[[30,0],\"confirm\"],null]]]]],[1,\"\\n\"]],[]]]]]],[]]]]]],[]]]]]],[1]]]]]],[\"card\",\"stack\"],false,[\"polaris-card\",\"polaris-stack\",\"polaris-display-text\",\"concat\",\"activityDefinition\",\"polaris-link\",\"action\",\"polaris-icon\",\"polaris-text-style\",\"polaris-button-group\",\"polaris-button\",\"isRunning\"]]",
    "moduleName": "smile-developers/templates/components/polaris-modals/delete-activity-definition.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});