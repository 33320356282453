define("smile-developers/transforms/array", ["exports", "@ember/array", "@ember/utils", "ember-data"], function (_exports, _array, _utils, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = _emberData.default.Transform;
  /*
   * Transform which ensures the attribute is always an array.
   * Usage in a model:
   *
   *   myArrayAttr: DS.attr('array'),
   *
   * or, to remove empty items from the array when serializing,
   *
   *   myArrayAttr: DS.attr('array', { serializeEmptyItems: false }),
   *
   */

  var _default = Transform.extend({
    deserialize: function deserialize(serialized) {
      if ((0, _array.isArray)(serialized)) {
        return (0, _array.A)(serialized);
      } else {
        return (0, _array.A)();
      }
    },
    serialize: function serialize(deserialized, _ref) {
      var _ref$serializeEmptyIt = _ref.serializeEmptyItems,
          serializeEmptyItems = _ref$serializeEmptyIt === void 0 ? true : _ref$serializeEmptyIt;

      if ((0, _array.isArray)(deserialized)) {
        var result = (0, _array.A)(deserialized);

        if (serializeEmptyItems) {
          return result;
        }

        return result.filter(function (item) {
          return (0, _utils.isPresent)(item);
        });
      } else {
        return (0, _array.A)();
      }
    }
  });

  _exports.default = _default;
});