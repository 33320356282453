define("smile-developers/services/error-handler", ["exports", "ember-smile-core/services/error-handler"], function (_exports, _errorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _errorHandler.default;
    }
  });
});