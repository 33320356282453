define("smile-developers/components/popup-menu-trigger", ["exports", "ember-smile-core/components/popup-menu-trigger"], function (_exports, _popupMenuTrigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _popupMenuTrigger.default;
    }
  });
});