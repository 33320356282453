define("smile-developers/templates/auth/recover", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "N7warXyO",
    "block": "[[[6,[39,0],null,[[\"smileGlyphSource\"],[\"/assets/images/smile/glyph-grey.svg\"]],[[\"default\"],[[[[6,[39,1],null,[[\"logoSrc\",\"headingText\",\"subheadingText\"],[\"/assets/icons/smile/logo-white-bg.svg\",\"Forgot your password?\",\"We'll email you instructions on how to reset it\"]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,2],null,[[\"email\",\"onRecover\",\"onReturnToLogin\"],[[28,[37,3],[[33,4]],null],[28,[37,5],[[33,6]],null],[28,[37,7],[\"goLogin\"],null]]]]],[1,\"\\n\"]],[]]]]]],[]]]]]],[],false,[\"auth-page\",\"auth-card\",\"auth-recover-form\",\"readonly\",\"email\",\"perform\",\"recoverPassword\",\"route-action\"]]",
    "moduleName": "smile-developers/templates/auth/recover.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});