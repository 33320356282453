define("smile-developers/templates/application", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qYLtUWXo",
    "block": "[[[1,[28,[35,0],[\"Smile Developers\"],null]],[1,\"\\n\\n\"],[6,[39,1],null,[[\"logoIcon\",\"homeRoute\",\"navContentComponent\",\"navToggleIcon\",\"profileTriggerComponent\",\"profileMenuComponent\",\"isOnMobile\",\"isTopBarVisible\",\"isNavEnabled\"],[[28,[37,2],[\"smile/logo-dark-bg\"],null],[28,[37,2],[\"managed-accounts\"],null],[28,[37,2],[\"app-nav-content\"],null],[28,[37,2],[\"ionicons/android-menu\"],null],[52,[33,4],[50,\"partner-user-profile\",0,null,[[\"partnerUser\",\"partnerAccount\"],[[33,4],[33,6]]]]],[52,[33,4],[50,\"partner-user-profile-menu\",0,null,null]],[28,[37,2],[[33,7,[\"isMobile\"]]],null],[28,[37,2],[[33,8]],null],[28,[37,2],[[33,9]],null]]],[[\"default\"],[[[[1,\"  \"],[46,[28,[37,10],null,null],null,null,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[1,[34,11]],[1,\"\\n\\n\"],[1,[34,12]],[1,\"\\n\\n\"],[10,0],[14,1,\"ember-basic-dropdown-wormhole\"],[12],[13],[1,\"\\n\"]],[],false,[\"page-title\",\"polaris-app\",\"readonly\",\"if\",\"partnerUser\",\"component\",\"partnerAccount\",\"media\",\"isTopBarVisible\",\"isNavEnabled\",\"-outlet\",\"polaris-modal-target\",\"flash-messages-container\"]]",
    "moduleName": "smile-developers/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});