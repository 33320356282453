define("smile-developers/templates/components/account/login-provider", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "g2o3X+AR",
    "block": "[[[18,1,[[28,[37,1],null,[[\"isRunning\",\"tasks\"],[[33,2,[\"isRunning\"]],[28,[37,1],null,[[\"login\"],[[33,2]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"login\"]]",
    "moduleName": "smile-developers/templates/components/account/login-provider.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});