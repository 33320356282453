define("smile-developers/templates/error-404", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wo/BOyDy",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@subtitle\",\"@imageSrc\",\"@primaryAction\"],[\"Feeling lost?\",\"Oops. The page you were looking for doesn't exist. The page may have been removed.\",\"/ember-smile-core/assets/images/not-found-error.png\",[50,\"polaris-button\",0,null,[[\"text\",\"size\",\"primary\",\"onClick\"],[\"Go home\",\"large\",true,[30,0,[\"goHome\"]]]]]]],null],[1,\"\\n\"]],[],false,[\"error-page\",\"component\"]]",
    "moduleName": "smile-developers/templates/error-404.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});