define("smile-developers/templates/components/route-page/apps/app/oauth-tab", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "PFGpyoad",
    "block": "[[[6,[39,0],null,[[\"title\",\"description\"],[\"OAuth tokens & URLs\",[50,\"app/oauth-tab/tokens-section-description\",0,null,null]]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,2],null,[[\"oauth2Application\"],[[33,3]]]]],[1,\"\\n\\n  \"],[1,[28,[35,4],null,[[\"appChangeset\",\"oauth2ApplicationChangeset\",\"onSave\"],[[33,5],[33,6],[28,[37,7],[[30,0],[33,8]],null]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[39,0],null,[[\"title\",\"description\"],[\"API Permissions\",[50,\"app/oauth-tab/permissions-section-description\",0,null,null]]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,9],null,[[\"oauth2Application\",\"oauth2ApplicationChangeset\"],[[33,3],[33,6]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"polaris-layout/annotated-section\",\"component\",\"oauth2-application/api-credentials-card\",\"oauth2Application\",\"app/oauth-card\",\"appChangeset\",\"oauth2ApplicationChangeset\",\"action\",\"onSave\",\"oauth2-application/api-permissions-card\"]]",
    "moduleName": "smile-developers/templates/components/route-page/apps/app/oauth-tab.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});