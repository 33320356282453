define("smile-developers/templates/components/webhook-event-definition/view-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "PYC23gt5",
    "block": "[[[6,[39,0],null,[[\"distribution\"],[\"equalSpacing\"]],[[\"default\"],[[[[6,[39,0],null,[[\"vertical\",\"spacing\"],[true,\"tight\"]],[[\"default\"],[[[[6,[39,0],null,[[\"alignment\",\"spacing\"],[\"center\",\"tight\"]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,1],null,[[\"variation\",\"text\"],[\"strong\",[33,2,[\"description\"]]]]]],[1,\"\\n\\n\"],[6,[39,3],null,[[\"class\",\"title\"],[\"hint-tooltip\",[33,4]]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,5],null,[[\"source\",\"color\",\"size\"],[\"circle-information\",\"inkLighter\",\"small\"]]]],[1,\"\\n\"]],[]]]]]],[]]]]],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"variation\",\"text\"],[\"subdued\",[33,2,[\"topic\"]]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[41,[33,7],[[[6,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[6,[39,8],null,[[\"onClick\"],[[33,9]]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,5],null,[[\"source\"],[\"delete\"]]]],[1,\"\\n\"]],[]]]]]],[]]]]]],[]],null]],[1]]]]]],[\"stack\"],false,[\"polaris-stack\",\"polaris-text-style\",\"webhookEventDefinition\",\"bs-tooltip\",\"requiredPermissionsString\",\"polaris-icon\",\"if\",\"shouldShowDeleteButton\",\"polaris-button\",\"onDelete\"]]",
    "moduleName": "smile-developers/templates/components/webhook-event-definition/view-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});