define("smile-developers/routes/logout", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    session: (0, _service.inject)(),
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      transition.send('invalidateSession');
    }
  });

  _exports.default = _default;
});