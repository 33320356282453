define("smile-developers/models/admin", ["exports", "ember-data", "@ember/object"], function (_exports, _emberData, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    /**
     * Attributes
     */
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),

    /**
     * Computed properties
     */
    fullName: (0, _object.computed)('firstName', 'lastName', function () {
      return "".concat(this.firstName, " ").concat(this.lastName);
    }).readOnly()
  });

  _exports.default = _default;
});