define("smile-developers/validations/app", ["exports", "ember-changeset-validations/validators", "smile-developers/utils/regex"], function (_exports, _validators, _regex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: (0, _validators.validatePresence)({
      presence: true,
      description: 'App name'
    }),
    oauthConnectUrl: (0, _validators.validateFormat)({
      regex: _regex.oauthUrlFormat,
      allowBlank: true,
      description: 'Oauth URL',
      message: "{description} is invalid (must have a protocol, e.g. 'https://')"
    })
  };
  _exports.default = _default;
});