define("smile-developers/templates/components/activity-definition/custom-card/item-details", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "OgTOT+Bp",
    "block": "[[[46,[33,1],null,null,[[\"default\"],[[[[6,[39,2],null,[[\"distribution\",\"alignment\"],[\"equalSpacing\",\"center\"]],[[\"default\"],[[[[1,\"\\n\"],[6,[30,1,[\"item\"]],null,[[\"fill\"],[true]],[[\"default\"],[[[[6,[39,3],null,null,[[\"default\"],[[[[1,\"        \"],[1,[28,[35,4],null,[[\"class\",\"text\"],[\"text-truncate\",[33,5,[\"name\"]]]]]],[1,\"\\n\\n        \"],[1,[28,[35,6],null,[[\"variation\",\"text\"],[\"subdued\",[33,5,[\"activityType\"]]]]]],[1,\"\\n\"]],[]]]]]],[]]]]],[1,\"\\n    \"],[1,[28,[35,7],null,[[\"text\",\"disabled\",\"onClick\"],[\"Edit\",[33,8],[28,[37,9],[\"showEditForm\",[30,0]],null]]]]],[1,\"\\n\"]],[1]]]]]],[]]]]],[1,\"\\n\"],[6,[39,10],[[33,11]],[[\"wrapperComponent\"],[[50,[33,1],0,null,[[\"subdued\"],[true]]]]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,12],null,[[\"customActivityDefinition\",\"onCancel\",\"onSaveCustomActivityDefinition\",\"onDeleteCustomActivityDefinition\"],[[33,5],[28,[37,13],[[30,0],[28,[37,14],[[33,11]],null],false],null],[28,[37,15],[[33,16]],null],[33,17]]]]],[1,\"\\n\"]],[]]]]]],[\"stack\"],false,[\"component\",\"wrapperComponent\",\"polaris-stack\",\"polaris-text-container\",\"polaris-heading\",\"customActivityDefinition\",\"polaris-text-style\",\"polaris-button\",\"showInstructions\",\"toggle\",\"polaris-liquid-if\",\"showEditForm\",\"activity-definition/custom-card/edit-form\",\"action\",\"mut\",\"perform\",\"saveCustomActivityDefinition\",\"onDeleteCustomActivityDefinition\"]]",
    "moduleName": "smile-developers/templates/components/activity-definition/custom-card/item-details.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});