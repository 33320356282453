define("smile-developers/components/polaris-date-picker/month", ["exports", "@smile-io/ember-smile-polaris/components/polaris-date-picker/month"], function (_exports, _month) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _month.default;
    }
  });
});