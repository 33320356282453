define("smile-developers/templates/components/partner-account-form-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DrFarYib",
    "block": "[[[6,[39,0],null,[[\"sectioned\"],[true]],[[\"default\"],[[[[6,[39,1],null,null,[[\"default\"],[[[[1,\"    \"],[1,[28,[35,2],null,[[\"label\",\"value\",\"error\",\"onChange\"],[\"Business name\",[33,3,[\"name\"]],[33,3,[\"error\",\"name\",\"validation\",\"firstObject\"]],[28,[37,4],[[30,0],[28,[37,5],[[33,3,[\"name\"]]],null]],null]]]]],[1,\"\\n\\n    \"],[1,[28,[35,2],null,[[\"label\",\"value\",\"error\",\"onChange\"],[\"Website\",[33,3,[\"websiteUrl\"]],[33,3,[\"error\",\"websiteUrl\",\"validation\",\"firstObject\"]],[28,[37,4],[[30,0],[28,[37,5],[[33,3,[\"websiteUrl\"]]],null]],null]]]]],[1,\"\\n\"]],[]]]]]],[]]]]]],[],false,[\"polaris-card\",\"polaris-form-layout\",\"polaris-text-field\",\"partnerAccount\",\"action\",\"mut\"]]",
    "moduleName": "smile-developers/templates/components/partner-account-form-card.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});