define("smile-developers/components/app/overview-card", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',

    /**
     * The app listing being viewed/edited.
     * @type {DS.Model}
     * @public
     * @default null
     */
    appListing: null,

    /**
     * Changeset for the app listing being viewed/edited.
     * @type {Changeset}
     * @public
     * @default null
     */
    appListingChangeset: null
  });

  _exports.default = _default;
});