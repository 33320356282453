define("smile-developers/components/bs-tooltip", ["exports", "@ember/component", "@ember/string", "@ember/object"], function (_exports, _component, _string, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    attributeBindings: ['style'],
    classNames: ['bs-tooltip-component'],

    /**
     * Which direction the tooltip should be rendered
     * in
     * @type {'top' | 'right' | 'bottom' | 'left'}
     * @public
     */
    placement: 'top',

    /**
     * The text displayed in the tooltip. The tooltip
     * will not be rendered if this is empty
     * @type {String}
     * @public
     */
    title: null,

    /**
     * style attrs
     */
    displayStyle: 'block',
    options: (0, _object.computed)(function () {
      return this.getProperties(['placement', 'title']);
    }),
    style: (0, _object.computed)('displayInline', function () {
      var styles = "\n      display: ".concat(this.displayStyle, ";\n    ");
      return (0, _string.htmlSafe)(styles);
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$().tooltip(this.options);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.$().attr('data-original-title', this.title);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.$().tooltip('dispose');
    }
  });

  _exports.default = _default;
});