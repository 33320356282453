define("smile-developers/templates/components/partner-user-profile", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zZlt9xUC",
    "block": "[[[1,[28,[35,0],null,[[\"size\",\"avatarSourcePath\",\"customer\",\"name\"],[\"small\",\"/assets/icons/polaris\",true,[33,1,[\"fullName\"]]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"user-profile-summary\"],[12],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"tagName\",\"class\",\"text\"],[\"div\",\"title\",[33,3,[\"name\"]]]]]],[1,\"\\n  \"],[10,0],[14,0,\"subtitle\"],[12],[1,[33,1,[\"fullName\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"polaris-avatar\",\"partnerUser\",\"polaris-text-style\",\"partnerAccount\"]]",
    "moduleName": "smile-developers/templates/components/partner-user-profile.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});