define("smile-developers/templates/components/webhook-event-definition/dropdown-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "eeGWKdie",
    "block": "[[[6,[39,0],null,[[\"vertical\",\"spacing\"],[true,\"none\"]],[[\"default\"],[[[[1,\"  \"],[1,[28,[30,1,[\"item\"]],null,[[\"text\"],[[33,1,[\"description\"]]]]]],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"variation\",\"text\"],[\"subdued\",[33,1,[\"topic\"]]]]]],[1,\"\\n\"]],[1]]]]]],[\"stack\"],false,[\"polaris-stack\",\"webhookEventDefinition\",\"polaris-text-style\"]]",
    "moduleName": "smile-developers/templates/components/webhook-event-definition/dropdown-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});