define("smile-developers/components/route-page/apps/app/features-tab", ["exports", "@ember/component", "@ember/debug"], function (_exports, _component, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @type {DS.Model}
     * @public
     */
    app: null,

    /**
     * @type {Function}
     * @public
     */
    onNavigateToOauthTab: null,
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(this.app) && (0, _debug.assert)('[route-page/apps/app/oauth-tab] missing required "app" attribute!', this.app));
      (false && !(this.onNavigateToOauthTab) && (0, _debug.assert)('[route-page/apps/app/oauth-tab] missing required "onNavigateToOauthTab" attribute!', this.onNavigateToOauthTab));
    }
  });

  _exports.default = _default;
});