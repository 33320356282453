define("smile-developers/helpers/format-size", ["exports", "ember-smile-core/helpers/format-size"], function (_exports, _formatSize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _formatSize.default;
    }
  });
  Object.defineProperty(_exports, "formatSize", {
    enumerable: true,
    get: function get() {
      return _formatSize.formatSize;
    }
  });
});