define("smile-developers/templates/components/app/oauth-card/redirect-url-help-text", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "OLcgq/oa",
    "block": "[[[1,\"Enter URLs on separate lines. After a merchant authorizes your app,\\nthey can be redirected back to any of these URLs. You can specify the URL\\nusing the\\n\"],[1,[28,[35,0],null,[[\"variation\",\"text\"],[\"strong\",\"redirect_url\"]]]],[1,\"\\nparameter during the authorization step.\\n\"]],[],false,[\"polaris-text-style\"]]",
    "moduleName": "smile-developers/templates/components/app/oauth-card/redirect-url-help-text.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});