define("smile-developers/templates/components/route-page/apps/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "i9thHFQR",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@primaryAction\"],[\"Apps\",[28,[37,1],null,[[\"text\",\"disabled\",\"onAction\"],[\"Create app\",[30,0,[\"showCreateAppModal\"]],[28,[37,2],[[30,0],[28,[37,3],[[30,0,[\"showCreateAppModal\"]]],null],true],null]]]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,6],null,[[\"@modelName\",\"@params\"],[\"app\",[28,[37,1],null,[[\"include\"],[\"app_listing\"]]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,7],null,[[\"@isLoading\",\"@items\",\"@emptyStateContent\"],[[30,2,[\"isLoading\"]],[30,2,[\"records\"]],[50,\"polaris-display-text\",0,null,[[\"size\",\"text\"],[\"small\",\"No apps to show\"]]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,9],null,[[\"@isLoading\",\"@apps\",\"@onAppClicked\"],[[30,3,[\"isLoading\"]],[30,3,[\"items\"]],[28,[37,2],[[30,0],\"showApp\"],null]]],null],[1,\"\\n          \"]],[3]]]]],[1,\"\\n        \"]],[2]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"showCreateAppModal\"]],[[[1,\"  \"],[8,[39,11],null,[[\"@modal\",\"@onConfirm\",\"@onClose\"],[\"create-app\",[28,[37,12],[[33,13]],null],[28,[37,2],[[30,0],[28,[37,3],[[30,0,[\"showCreateAppModal\"]]],null],false],null]]],null],[1,\"\\n\"]],[]],null]],[\"layout\",\"appLoader\",\"dataTableWrapper\"],false,[\"polaris-page\",\"hash\",\"action\",\"mut\",\"polaris-layout\",\"polaris-card\",\"data-loader\",\"data-table-wrapper\",\"component\",\"app/data-table\",\"if\",\"polaris-modal\",\"perform\",\"createApp\"]]",
    "moduleName": "smile-developers/templates/components/route-page/apps/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});