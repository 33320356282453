define("smile-developers/components/polaris-spinner", ["exports", "@smile-io/ember-smile-polaris/components/polaris-spinner"], function (_exports, _polarisSpinner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _polarisSpinner.default;
    }
  });
});