define("smile-developers/components/polaris-modals/delete-oauth2-application-client-secret", ["exports", "ember-smile-core/components/polaris-modals/base", "@ember/object/computed"], function (_exports, _base, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    isSecretPrimary: (0, _computed.readOnly)('modalParams.isSecretPrimary'),
    secret: (0, _computed.readOnly)('modalParams.secret'),
    isRunning: (0, _computed.readOnly)('modalParams.isRunning')
  });

  _exports.default = _default;
});