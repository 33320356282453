define("smile-developers/utils/partner-resources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    generalResources: [{
      text: 'Lead Submission Form',
      url: 'https://bit.ly/2OZ6stC'
    }, {
      text: 'Smile.io Knowledge Base',
      url: 'https://bit.ly/2Jsng5W'
    }, {
      text: 'Demo Store',
      url: 'https://bit.ly/2yFOUZj'
    }, {
      text: 'Subscribe to the Partner Newsletter',
      url: 'https://bit.ly/2JYY7Ae'
    }],
    technicalDocumentation: [{
      text: 'Contact partners@smile.io for API Documentation',
      url: 'mailto:partners@smile.io'
    }],
    rewardsResources: [{
      text: 'Video: How & When To Talk Rewards With Client',
      url: 'https://wi.st/2EdniiB'
    }, {
      text: 'Community Building 101',
      url: 'https://bit.ly/2PttSXf'
    }, {
      text: 'Reward Psychology 101',
      url: 'https://bit.ly/2ETigZM'
    }, {
      text: "How to Build a Rewards Program Like the World's Best",
      url: 'https://bit.ly/2COe4b0'
    }, {
      text: 'Is a Rewards Program Right for You?',
      url: 'https://bit.ly/2Jqecie'
    }],
    industryGuides: [{
      text: 'Apparel',
      url: 'https://bit.ly/2ET3X7o'
    }, {
      text: 'Cosmetics',
      url: 'https://bit.ly/2JqZtUb'
    }, {
      text: 'eCigs and Vapes',
      url: 'https://bit.ly/2AAab7V'
    }, {
      text: 'Electronics',
      url: 'https://bit.ly/2SyxrtQ'
    }, {
      text: 'Food & Beverage',
      url: 'https://bit.ly/2CT9599'
    }, {
      text: 'Jewelry',
      url: 'https://bit.ly/2OUKKqq'
    }, {
      text: 'Luxury',
      url: 'https://bit.ly/2qgRf8a'
    }, {
      text: 'Subscriptions',
      url: 'https://bit.ly/2yIQolm'
    }, {
      text: 'Supplements',
      url: 'https://bit.ly/2qf3138'
    }, {
      text: 'T-Shirts',
      url: 'https://bit.ly/2PChl49'
    }],
    smileResources: [{
      text: 'Smile Overview One Pager',
      url: 'https://bit.ly/2qj0WDp'
    }],
    caseStudies: [{
      text: 'Topps',
      url: 'https://bit.ly/2OdbAVC'
    }, {
      text: 'Run Gum',
      url: 'https://bit.ly/2qih87J'
    }, {
      text: 'Mpix',
      url: 'https://bit.ly/2OfRGcG'
    }, {
      text: 'Inkbox',
      url: 'https://bit.ly/2Od3YCt'
    }, {
      text: "Evy's Tree",
      url: 'https://bit.ly/2yFm0bE'
    }],
    launchResources: [{
      text: 'Merchant Design Kit',
      url: 'https://bit.ly/2CQv5RN'
    }, {
      text: 'Video Onboarding Series',
      url: 'https://bit.ly/2DgpG7t'
    }, {
      text: 'PDF Onboarding Series',
      url: 'https://bit.ly/2zjENsp'
    }],
    strategy: [{
      text: 'How to Launch an Online Rewards Program ',
      url: 'https://bit.ly/2AzmDVq'
    }, {
      text: 'Points Program Strategy',
      url: 'https://bit.ly/2qh0UvA'
    }, {
      text: 'Referral Program Strategy',
      url: 'https://bit.ly/2Og1jbf'
    }, {
      text: 'VIP Program Strategy',
      url: 'https://bit.ly/2Dd66cr'
    }, {
      text: 'How to Run an Effective Launch Email Campaign',
      url: 'https://bit.ly/2Dd6GqD'
    }]
  };
  _exports.default = _default;
});