define("smile-developers/components/polaris-modals/delete-activity-definition", ["exports", "ember-smile-core/components/polaris-modals/base", "@ember/object/computed"], function (_exports, _base, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    classNames: ['polaris-modals-delete-activity-definition-component'],
    activityDefinition: (0, _computed.readOnly)('modalParams.activityDefinition'),
    isRunning: (0, _computed.readOnly)('modalParams.isRunning')
  });

  _exports.default = _default;
});