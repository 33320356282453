define("smile-developers/models/webhook", ["exports", "ember-data", "ember-api-actions", "@ember/object", "smile-developers/utils/models/serialize-and-push"], function (_exports, _emberData, _emberApiActions, _object, _serializeAndPush) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  var topicOptions = [{
    label: 'Customer updated',
    value: 'customer/updated'
  }];

  var _default = Model.extend({
    /**
     * Attributes
     */
    type: attr('string'),
    urlTemplate: attr('string'),
    topics: attr('array'),
    isEnabled: attr('boolean'),

    /**
     * Relationships
     */
    app: belongsTo('app', {
      async: false
    }),

    /**
     * Computed properties
     */
    isUnhealthy: (0, _object.computed)('isNew', 'isEnabled', function () {
      if (this.isNew) {
        return false;
      }

      return !this.isEnabled;
    }).readOnly(),

    /**
     * API actions
     */
    sendTest: (0, _emberApiActions.memberAction)({
      path: 'send_test',
      type: 'post'
    }),
    enable: (0, _emberApiActions.memberAction)({
      path: 'enable',
      after: _serializeAndPush.serializeAndPush
    }),
    // Webhook topic options
    topicOptions: topicOptions
  });

  _exports.default = _default;
});