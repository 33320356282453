define("smile-developers/templates/components/app/oauth-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "z6RwhTix",
    "block": "[[[6,[39,0],null,[[\"sectioned\"],[true]],[[\"default\"],[[[[6,[39,1],null,[[\"onSubmit\"],[[28,[37,2],[[30,0],[33,3]],null]]],[[\"default\"],[[[[6,[39,4],null,null,[[\"default\"],[[[[1,\"      \"],[1,[28,[35,5],null,[[\"label\",\"placeholder\",\"helpText\",\"value\",\"error\",\"onChange\"],[\"Install URL\",\"e.g. https://app.example.com\",\"Merchants that land on this URL should be redirected into the Smile OAuth flow.\",[33,6,[\"oauthConnectUrl\"]],[33,6,[\"error\",\"oauthConnectUrl\",\"validation\",\"firstObject\"]],[28,[37,2],[[30,0],[28,[37,7],[[33,6,[\"oauthConnectUrl\"]]],null]],null]]]]],[1,\"\\n\\n      \"],[1,[28,[35,5],null,[[\"multiline\",\"label\",\"placeholder\",\"helpText\",\"value\",\"error\",\"onChange\"],[3,\"Whitelisted redirection URL(s)\",\"e.g. https://example.com/auth/smile/callback\",[50,\"app/oauth-card/redirect-url-help-text\",0,null,null],[33,9,[\"redirectUrisForTextareaField\"]],[33,9,[\"error\",\"redirectUrisForTextareaField\",\"validation\",\"firstObject\"]],[28,[37,2],[[30,0],\"setRedirectUris\",[33,9]],null]]]]],[1,\"\\n\"]],[]]]]]],[]]]]]],[]]]]]],[],false,[\"polaris-card\",\"polaris-form\",\"action\",\"onSave\",\"polaris-form-layout\",\"polaris-text-field\",\"appChangeset\",\"mut\",\"component\",\"oauth2ApplicationChangeset\"]]",
    "moduleName": "smile-developers/templates/components/app/oauth-card.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});