define("smile-developers/components/flash-messages-container", ["exports", "ember-smile-core/components/flash-messages-container"], function (_exports, _flashMessagesContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _flashMessagesContainer.default;
    }
  });
});