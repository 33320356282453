define("smile-developers/components/route-page/apps/app/oauth-tab", ["exports", "@ember/component", "@ember/debug", "rsvp"], function (_exports, _component, _debug, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @type {DS.Model}
     * @public
     */
    oauth2Application: null,

    /**
     * @type {Changeset}
     * @public
     */
    oauth2ApplicationChangeset: null,

    /**
     * @type {Changeset}
     * @public
     */
    appChangeset: null,

    /**
     * Callback triggered on submitting the form
     *
     * @type {Function}
     * @public
     */
    onSave: _rsvp.resolve,
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(this.oauth2Application) && (0, _debug.assert)('[route-page/apps/app/oauth-tab] missing required "oauth2Application" attribute!', this.oauth2Application));
      (false && !(this.oauth2ApplicationChangeset) && (0, _debug.assert)('[route-page/apps/app/oauth-tab] missing required "oauth2ApplicationChangeset" attribute!', this.oauth2ApplicationChangeset));
      (false && !(this.appChangeset) && (0, _debug.assert)('[route-page/apps/app/oauth-tab] missing required "appChangeset" attribute!', this.appChangeset));
    }
  });

  _exports.default = _default;
});