define("smile-developers/validations/activity-definition", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: (0, _validators.validatePresence)({
      presence: true,
      description: 'Name before completed'
    }),
    namePastTense: (0, _validators.validatePresence)({
      presence: true,
      description: 'Name after completed'
    })
  };
  _exports.default = _default;
});