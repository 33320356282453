define("smile-developers/models/billing-product", ["exports", "@ember-data/model", "@ember/object", "@ember/object/computed"], function (_exports, _model, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    isPlanType: (0, _computed.equal)('type', 'plan').readOnly(),
    displayName: (0, _object.computed)('name', function () {
      return this.name.replace(' Plan', '');
    }).readOnly()
  });

  _exports.default = _default;
});