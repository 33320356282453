define("smile-developers/models/oauth2-permission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.activityWritePermission = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  var activityWritePermission = 'activity:write';
  _exports.activityWritePermission = activityWritePermission;

  var _default = Model.extend({
    /**
     * Attributes
     */
    name: attr('string'),
    description: attr('string'),
    optionName: attr('string'),

    /**
     * Associations
     */
    oauth2PermissionGroup: belongsTo('oauth2-permission-group', {
      async: false
    })
  });

  _exports.default = _default;
});