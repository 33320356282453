define("smile-developers/templates/components/app/features-tab/webhooks-section-description", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RfKmM48e",
    "block": "[[[10,0],[12],[1,\"\\n  Webhooks let you subscribe to events that happen in Smile and receive\\n  a POST request to your URL in real-time.\\n\"],[13],[1,\"\\n\"],[10,0],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"external\",\"text\",\"url\"],[true,\"Learn more about webhooks\",\"https://docs.smile.io/docs/integrate-your-product/build-a-smile-app/features-and-functionality/webhooks\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"polaris-link\"]]",
    "moduleName": "smile-developers/templates/components/app/features-tab/webhooks-section-description.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});