define("smile-developers/components/webhook-event-definition/view-item", ["exports", "@ember/component", "@ember/debug", "@ember/object", "@ember/utils"], function (_exports, _component, _debug, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * The model that will be displayed
     * @type {DS.Model}
     * @public
     */
    webhookEventDefinition: null,

    /**
     * Callback for when the delete button is pressed
     * @type {Function}
     * @public
     */
    onDelete: null,

    /**
     * If we should show the delete button
     * @type {Boolean}
     * @public
     */
    shouldShowDeleteButton: false,

    /**
     * The permissions required in order to use the event
     * @type {String}
     * @private
     */
    requiredPermissionsString: (0, _object.computed)('webhookEventDefinition.requiredPermissions', function () {
      var requiredPermissions = this.get('webhookEventDefinition.requiredPermissions');

      if ((0, _utils.isEmpty)(requiredPermissions)) {
        return 'This event does not require any permissions';
      }

      return "This event requires ".concat(requiredPermissions.mapBy('name'));
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(this.webhookEventDefinition) && (0, _debug.assert)('[webhook-event-definition/view-item] missing required "webhookEventDefinition" attribute!', this.webhookEventDefinition));
      (false && !(this.onDelete) && (0, _debug.assert)('[webhook-event-definition/view-item] missing required "onDelete" attribute!', this.onDelete));
    }
  });

  _exports.default = _default;
});