define("smile-developers/templates/components/route-page/apps/app/features-tab", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2qHgxLRS",
    "block": "[[[6,[39,0],null,[[\"title\",\"description\"],[\"Activity types\",[50,\"app/features-tab/activity-section-description\",0,null,null]]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,2],null,[[\"app\",\"onNavigateToOauthTab\"],[[33,3],[33,4]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[39,0],null,[[\"title\",\"description\"],[\"Webhooks\",[50,\"app/features-tab/webhooks-section-description\",0,null,null]]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,5],null,[[\"app\"],[[33,3]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"polaris-layout/annotated-section\",\"component\",\"activity-definition/custom-card\",\"app\",\"onNavigateToOauthTab\",\"webhook/manage-section\"]]",
    "moduleName": "smile-developers/templates/components/route-page/apps/app/features-tab.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});