define("smile-developers/helpers/polaris-data-table/insert-presentational-cell", ["exports", "@smile-io/ember-smile-polaris/helpers/polaris-data-table/insert-presentational-cell"], function (_exports, _insertPresentationalCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _insertPresentationalCell.default;
    }
  });
  Object.defineProperty(_exports, "polarisDataTableInsertPresentationalCell", {
    enumerable: true,
    get: function get() {
      return _insertPresentationalCell.polarisDataTableInsertPresentationalCell;
    }
  });
});