define("smile-developers/serializers/app", ["exports", "smile-developers/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      appListing: {
        deserialize: 'records',
        serialize: false
      },
      oauth2Application: {
        deserialize: 'records',
        serialize: false
      },
      activityDefinitions: {
        deserialize: 'records',
        serialize: false
      },
      webhooks: {
        deserialize: 'records',
        serialize: false
      }
    }
  });

  _exports.default = _default;
});