define("smile-developers/models/oauth2-application", ["exports", "ember-data", "@ember/object", "ember-api-actions", "smile-developers/utils/models/serialize-and-push"], function (_exports, _emberData, _object, _emberApiActions, _serializeAndPush) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    /**
     * Attributes
     */
    clientId: attr('string'),
    clientSecret: attr('string'),
    clientSecretNew: attr('string'),
    clientSecretCreatedAt: attr('date'),
    clientSecretNewCreatedAt: attr('date'),
    permissions: attr('array'),
    redirectUris: attr(),

    /**
     * CPs
     */
    redirectUrisForTextareaField: (0, _object.computed)('redirectUris', function () {
      return this.redirectUris.compact().join('\n');
    }),

    /**
     * API Actions
     */
    generateClientSecret: (0, _emberApiActions.memberAction)({
      path: 'generate_client_secret',
      type: 'patch',
      after: _serializeAndPush.serializeAndPush
    }),
    revokeClientSecret: (0, _emberApiActions.memberAction)({
      path: 'revoke_client_secret',
      type: 'patch',
      after: _serializeAndPush.serializeAndPush
    })
  });

  _exports.default = _default;
});