define("smile-developers/templates/components/partner-user-profile-menu", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "KC3+s1Gj",
    "block": "[[[1,[28,[35,0],null,[[\"sections\"],[[28,[37,1],[[28,[37,2],null,[[\"items\"],[[28,[37,1],[[28,[37,2],null,[[\"text\",\"icon\",\"onAction\"],[\"Your profile\",\"smile/profile\",[28,[37,3],[[28,[37,4],[\"settings\"],null],[28,[37,5],[[30,0],[33,6]],null]],null]]]],[28,[37,2],null,[[\"text\",\"icon\",\"onAction\"],[\"Logout\",\"smile/logout\",[28,[37,7],[[28,[37,4],[\"logout\"],null],[28,[37,5],[[30,0],[33,6]],null]],null]]]]],null]]]],[28,[37,2],null,[[\"items\"],[[28,[37,1],[[28,[37,2],null,[[\"text\",\"onAction\"],[\"Support\",[28,[37,7],[[28,[37,8],[\"redirectToExternal\",\"https://help.smile.io\"],null],[28,[37,5],[[30,0],[33,6]],null]],null]]]]],null]]]]],null]]]]],[1,\"\\n\"]],[],false,[\"polaris-action-list\",\"array\",\"hash\",\"queue\",\"transition-to\",\"action\",\"onSelect\",\"pipe\",\"route-action\"]]",
    "moduleName": "smile-developers/templates/components/partner-user-profile-menu.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});