define("smile-developers/utils/models/build-url", ["exports", "@ember/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getModelClass = getModelClass;
  _exports.getModelName = getModelName;
  _exports.getStoreFromRecord = getStoreFromRecord;

  /**
   * Utils to work with Ember Data stuff
   * Mostly inspired from `ember-api-actions` build-url util
   */

  /**
   * Given a record, obtain the ember-data model class
   * @param record
   */
  function getModelClass(record) {
    return record.constructor;
  }
  /**
   * Given an ember-data model class, obtain its name
   * @param clazz
   */


  function getModelName(clazz) {
    return (// modern use || legacy fallback
      clazz.modelName || clazz.typeKey
    );
  }
  /**
   * Given an ember-data-record, obtain the related Store
   * @param record
   */


  function getStoreFromRecord(record) {
    var owner = (0, _application.getOwner)(record);
    return owner.lookup('service:store');
  }
});