define("smile-developers/components/partner-account-form-card", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',

    /**
     * Partner account to edit.
     * @type {Model}
     * @public
     */
    partnerAccount: null
  });

  _exports.default = _default;
});