define("smile-developers/models/webhook-event-definition", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    /**
     * Attributes
     */
    name: attr('string'),
    topic: attr('string'),
    description: attr('string'),

    /**
     * Associations
     */
    requiredPermissions: hasMany('oauth2-permission', {
      async: false
    })
  });

  _exports.default = _default;
});