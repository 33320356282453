define("smile-developers/templates/components/resources/why-smile", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "MboRMrQf",
    "block": "[[[6,[39,0],null,[[\"title\",\"sectioned\"],[\"Smile resources\",true]],[[\"default\"],[[[[42,[28,[37,2],[[28,[37,2],[[33,3,[\"smileResources\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,4],null,[[\"external\",\"url\",\"text\"],[true,[30,1,[\"url\"]],[30,1,[\"text\"]]]]]],[1,\"\\n\"]],[1]],null]],[]]]]],[1,\"\\n\"],[6,[39,0],null,[[\"title\",\"sectioned\"],[\"Case studies\",true]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,5],null,[[\"triggerTitle\",\"listItems\"],[\"case studies\",[33,3,[\"caseStudies\"]]]]]],[1,\"\\n\"]],[]]]]]],[\"resource\"],false,[\"polaris-card\",\"each\",\"-track-array\",\"partnerResources\",\"polaris-link\",\"collapsible-list\"]]",
    "moduleName": "smile-developers/templates/components/resources/why-smile.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});