define("smile-developers/components/remove-editable-benefit-button", ["exports", "@ember/component", "rsvp"], function (_exports, _component, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    onClick: _rsvp.resolve
  });

  _exports.default = _default;
});