define("smile-developers/components/partner-user-profile", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * Ther partner user logged in.
     * @type {Model}
     */
    partnerUser: null
  });

  _exports.default = _default;
});