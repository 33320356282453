define("smile-developers/routes/managed-accounts/account/login", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    model: function model(_ref) {
      var accountId = _ref.account_id;
      return accountId;
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('accountId', model);
    },
    activate: function activate() {
      this._super.apply(this, arguments);

      this.send('disableNav');
      this.send('disableTopBar');
    }
  });

  _exports.default = _default;
});