define("smile-developers/templates/components/remove-editable-benefit-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "sOYYWbC7",
    "block": "[[[6,[39,0],null,[[\"onClick\"],[[28,[37,1],[[30,0],[33,2]],null]]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,3],null,[[\"source\"],[\"delete\"]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"polaris-button\",\"action\",\"onClick\",\"polaris-icon\"]]",
    "moduleName": "smile-developers/templates/components/remove-editable-benefit-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});