define("smile-developers/components/actionable-list", ["exports", "ember-smile-core/components/actionable-list"], function (_exports, _actionableList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _actionableList.default;
    }
  });
});