define("smile-developers/models/billing-subscription", ["exports", "@ember-data/model", "@ember/object", "@ember/object/computed"], function (_exports, _model, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.states = void 0;
  var states = {
    active: 'active',
    archived: 'archived',
    cancelled: 'cancelled',
    declined: 'declined',
    offered: 'offered',
    pastDue: 'past_due',
    pending: 'pending',
    revoked: 'revoked'
  };
  _exports.states = states;

  var _default = _model.default.extend({
    state: (0, _model.attr)('string'),
    billingSubscriptionDefaults: (0, _model.hasMany)('billing-subscription-default', {
      async: false
    }),
    basePlan: (0, _computed.readOnly)('baseSubscriptionDefault.newBillingPlan'),
    isActive: (0, _computed.equal)('state', states.active).readOnly(),
    isPastDue: (0, _computed.equal)('state', states.pastDue).readOnly(),
    isChargeable: (0, _computed.or)('isActive', 'isPastDue').readOnly(),
    friendlyBasePlanName: (0, _computed.readOnly)('basePlan.billingProduct.displayName'),
    baseSubscriptionDefault: (0, _object.computed)('billingSubscriptionDefaults.@each.isBaseDefault', function () {
      return this.billingSubscriptionDefaults.findBy('isBaseDefault');
    }).readOnly()
  });

  _exports.default = _default;
});