define("smile-developers/components/oauth2-permission-group/pick-permission", ["exports", "@ember/component", "@ember/debug", "@ember/object/computed", "@ember/object"], function (_exports, _component, _debug, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @type {DS.Model}
     * @public
     */
    oauth2PermissionGroup: null,

    /**
     * @type {DS.Model}
     * @public
     */
    oauth2Application: null,

    /**
     * Called when an oauth2Permission is chosen
     * @type {Function}
     * @public
     */
    onSetPermission: null,

    /**
     * @type {DS.RecordArray}
     * @private
     */
    groupOauth2Permissions: (0, _computed.readOnly)('oauth2PermissionGroup.oauth2Permissions'),

    /**
     * The permission in the group that is enabled by the app
     * @type {DS.Model | null}
     * @private
     */
    selectedPermission: (0, _object.computed)('oauth2Application.permissions', function () {
      var _this = this;

      return this.groupOauth2Permissions.find(function (oauth2Permission) {
        return _this.get('oauth2Application.permissions').includes(oauth2Permission.get('name'));
      });
    }).readOnly(),

    /**
     * @type {Object[]}
     * @private
     */
    oauth2PermissionOptions: (0, _object.computed)('groupOauth2Permissions.[]', function () {
      var groupOauth2Permissions = this.groupOauth2Permissions;
      var options = groupOauth2Permissions.mapBy('optionName');
      options.unshift({
        value: null,
        label: 'No access'
      });
      return options;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(this.oauth2PermissionGroup) && (0, _debug.assert)('[oauth2-permission-group/pick-permission] missing required "oauth2PermissionGroup" attribute!', this.oauth2PermissionGroup));
      (false && !(this.oauth2Application) && (0, _debug.assert)('[oauth2-permission-group/pick-permission] missing required "oauth2Application" attribute!', this.oauth2Application));
      (false && !(this.onSetPermission) && (0, _debug.assert)('[oauth2-permission-group/pick-permission] missing required "onSetPermission" attribute!', this.onSetPermission));
    },
    actions: {
      selectPermission: function selectPermission(value) {
        var permission = this.groupOauth2Permissions.findBy('optionName', value);
        this.onSetPermission(permission, this.oauth2PermissionGroup);
      }
    }
  });

  _exports.default = _default;
});