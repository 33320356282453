define("smile-developers/validators/array", ["exports", "@ember/utils", "@ember/array", "@ember/debug"], function (_exports, _utils, _array, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateArray;

  /**
   * `validateArray` will validate an array with the validator(s) given.
   */
  function validateArray() {
    for (var _len = arguments.length, validators = new Array(_len), _key = 0; _key < _len; _key++) {
      validators[_key] = arguments[_key];
    }

    (false && !((0, _utils.isPresent)(validators)) && (0, _debug.assert)("[validator:array] No validator given", (0, _utils.isPresent)(validators)));
    validators = (0, _array.A)(validators);
    return function (key, newValue, oldValue, changes, content) {
      (false && !((0, _array.isArray)(newValue)) && (0, _debug.assert)("[validator:array] [".concat(key, "] Value is not an array"), (0, _array.isArray)(newValue)));

      for (var i = validators.length - 1; i >= 0; i--) {
        var validation = void 0;
        var validator = validators[i];

        for (var j = newValue.length - 1; j >= 0; j--) {
          var newValueItem = newValue[j];
          var oldValueItem = oldValue[j] || null; // Validate each value in the array with the validator

          validation = validator(key, newValueItem, oldValueItem, changes, content);

          if (isInvalid(validation)) {
            return validation;
          }
        } // If the array is empty, validate it


        if ((0, _utils.isEmpty)(newValue)) {
          validation = validator(key, newValue, oldValue, changes, content);

          if (isInvalid(validation)) {
            return validation;
          }
        }
      }

      return true;
    };
  }

  function isInvalid(validation) {
    return (0, _utils.typeOf)(validation) !== 'boolean' || !validation;
  }
});