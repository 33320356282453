define("smile-developers/helpers/validate-changeset", ["exports", "ember-smile-core/helpers/validate-changeset"], function (_exports, _validateChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _validateChangeset.default;
    }
  });
  Object.defineProperty(_exports, "validateChangeset", {
    enumerable: true,
    get: function get() {
      return _validateChangeset.validateChangeset;
    }
  });
});