define("smile-developers/templates/apps/app", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "LETo/BNm",
    "block": "[[[1,[28,[35,0],null,[[\"app\"],[[33,1]]]]],[1,\"\\n\"]],[],false,[\"route-page/apps/app\",\"app\"]]",
    "moduleName": "smile-developers/templates/apps/app.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});