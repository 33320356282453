define("smile-developers/components/icon-upload", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    sesh: (0, _service.inject)(),
    session: (0, _service.inject)(),
    config: (0, _service.inject)(),
    errorHandler: (0, _service.inject)(),

    /**
     * Existing icon url, if any.
     *
     * @type {String}
     * @public
     */
    iconUrl: null,

    /**
     * Title
     *
     * @type {String}
     * @public
     */
    title: 'Icon',

    /**
     * Text shown to the merchant about recommended icon.
     *
     * @type {String}
     * @public
     */
    iconRecommendation: 'Must be a .jpg or .png, and minimum of 96x96px',

    /**
     * Actions triggered when a file is succesfully uploaded
     *
     * @type {Function}
     * @public
     */
    onUploadedFileKey: function onUploadedFileKey() {},

    /**
     * Used to manually open the file dialog picker when clicking the Upload button.
     *
     * @type {Boolean}
     * @default false
     * @private
     */
    showImageUploadDialog: false,
    onUploadedFileUrl: function onUploadedFileUrl() {},
    actions: {
      setIcon: function setIcon(_ref) {
        var uploadedKey = _ref.uploadedKey,
            uploadedUrl = _ref.uploadedUrl;
        this.onUploadedFileKey(uploadedKey);
        this.onUploadedFileUrl(uploadedUrl);
      },
      handleError: function handleError(err) {
        this.errorHandler.handle(err);
      }
    }
  });

  _exports.default = _default;
});