define("smile-developers/utils/normalize-auto-complete", ["exports", "@smile-io/ember-smile-polaris/utils/normalize-auto-complete"], function (_exports, _normalizeAutoComplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _normalizeAutoComplete.default;
    }
  });
});