define("smile-developers/templates/error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "U5Y58W37",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"]],[],false,[\"error-page\"]]",
    "moduleName": "smile-developers/templates/error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});